import React from 'react';

import PropTypes from 'prop-types';

import { Switch, Tab, TabList, Flex } from '@chakra-ui/react';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';
import AuthGate from '../../containers/auth/AuthGate';
import Constants from '../../Constants';

const OrderManagementTabList = ({ handleNavigation, tabs,poGridtoggleStatus,handleToggleChange }) => {  
  return (
    <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
      <TabList borderBottomWidth="0px">
        {tabs.map(tab => (
          <AuthGate
            key={tab.name}
            requiredPermissions={
              Constants.ORDER_MANAGEMENT_PERMISSIONS[tab.name]
                ? [Constants.ORDER_MANAGEMENT_PERMISSIONS[tab.name]]
                : undefined
            }
          >
            <Tab
              key={tab.name}
              _focus={{ outline: 'none' }}
              _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
              _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
              fontWeight="normal"
              width="162px"
              fontSize="14px"
              padding="0px"
              height="103px"
              marginRight="10px"
              onClick={() => handleNavigation(tab.type, tab.name)}
            >
              {tab.label}
            </Tab>
          </AuthGate>
        ))}
      </TabList>

      <Flex alignItems="right" justifyContent="flex-end" padding="0px" marginLeft="auto">
        <span style={{ marginRight: '8px' }}>PO Grid</span>        
        <Switch
          isChecked={poGridtoggleStatus}
          onChange={handleToggleChange}
          colorScheme="teal"
        />
      </Flex>
    </HorizontalNavigationBand>
  );
};

OrderManagementTabList.propTypes = {
  handleNavigation: PropTypes.func,

  tabs: PropTypes.arrayOf(
    PropTypes.objectOf({
      type: PropTypes.string,

      name: PropTypes.string,

      label: PropTypes.string,
    })
  ),
};

export default OrderManagementTabList;
