import { get, isNil } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import qs from 'query-string';

import api from '../api';
import Constants from '../Constants';
import { fetchDailyTaskDetails } from './actions_daily_task_details';
import { VL_BASE_URL } from './actions_voyage_legs';
import { convertToSnakeCase } from '../helpers';

export const ADD_LINE = 'ADD_LINE';
export const COLD_STORE_SEND_EMAIL_ERROR = 'COLD_STORE_SEND_EMAIL_ERROR';
export const CONF_DOC_EMAIL_LOADING = 'CONF_DOC_EMAIL_LOADING';
export const CONF_DOC_EMAIL_SENT = 'CONF_DOC_EMAIL_SENT';
export const CONF_DOC_GENERATED = 'CONF_DOC_GENERATED';
export const CONF_DOC_RESET = 'CONF_DOC_RESET';
export const CONFIRMATION_DOC_LOADING = 'CONFIRMATION_DOC_LOADING';
export const DELETE_ORDER = 'DELETE_ORDER';
export const EMAIL_CONF_DOC_RESET = 'EMAIL_CONF_DOC_RESET';
export const FINISH_ORDER_CREATION = 'FINISH_ORDER_CREATION';
export const FINISH_ORDER_UPDATE = 'FINISH_ORDER_UPDATE';
export const LOAD_EDIT_ORDER = 'LOAD_EDIT_ORDER';
export const LOAD_FAST_ORDERS = 'LOAD_FAST_ORDERS';
export const LOAD_NETSUITE_ORDER = 'LOAD_NETSUITE_ORDER';
export const LOAD_ORDER = 'LOAD_ORDER';
export const LOAD_ORDER_HISTORY = 'LOAD_ORDER_HISTORY';
export const EXPORT_LOAD_ORDER_HISTORY = 'EXPORT_LOAD_ORDER_HISTORY';
export const LOAD_ORDER_HISTORY_BY_USER = 'LOAD_ORDER_HISTORY_BY_USER';
export const LOAD_ORDER_PENDING = 'LOAD_ORDER_PENDING';
export const LOAD_PENDING_ORDERS = 'LOAD_PENDING_ORDERS';
export const ORDER_ERROR = 'ORDER_ERROR';
export const PREVIEW_PDF = 'PREVIEW_PDF';
export const RELEASE_PDF_EMAIL_SENT = 'RELEASE_PDF_EMAIL_SENT';
export const REPLACE_ORDER = 'REPLACE_ORDER';
export const REPLACE_ORDERS = 'REPLACE_ORDERS';
export const REQUEST_NETSUITE_ORDER = 'REQUEST_NETSUITE_ORDER';
export const RESET_COLD_STORE_MODAL = 'RESET_COLD_STORE_MODAL';
export const RESET_MODAL_ORDER = 'RESET_ORDER';
export const RESET_ORDER = 'RESET_ORDER';
export const RESET_PURCHASE_ORDERS = 'RESET_PURCHASE_ORDERS';
export const START_ORDER_CREATION = 'START_ORDER_CREATION';
export const START_ORDER_UPDATE = 'START_ORDER_UPDATE';
export const START_SEARCH = 'START_SEARCH';

export function resetConfDoc() {
  return {
    type: CONF_DOC_RESET,
  };
}

export function emailConfDocReset() {
  return {
    type: EMAIL_CONF_DOC_RESET,
  };
}

export function startSearch() {
  return {
    type: START_SEARCH,
  };
}

export function errorOrder(error) {
  return {
    type: ORDER_ERROR,
    payload: error,
  };
}

export function clearOrders() {
  return {
    type: LOAD_ORDER_HISTORY,
    payload: [],
  };
}

export function addLineSuccess(lineId) {
  return {
    type: ADD_LINE,
    payload: lineId,
  };
}

export function startOrderCreation() {
  return {
    type: START_ORDER_CREATION,
  };
}

export function finishOrderCreation() {
  return {
    type: FINISH_ORDER_CREATION,
  };
}

export function resetModalOrder() {
  return {
    type: RESET_MODAL_ORDER,
  };
}

export function resetPurchaseOrders() {
  return {
    type: RESET_PURCHASE_ORDERS,
  };
}

export function deleteOrder(order) {
  return {
    type: DELETE_ORDER,
    payload: order,
  };
}

export function loadOrder(order) {
  return {
    type: LOAD_ORDER,
    payload: order,
  };
}

export function loadEditOrder(order) {
  return {
    type: LOAD_EDIT_ORDER,
    payload: order,
  };
}

export function loadFastOrders(orders) {
  return {
    type: LOAD_FAST_ORDERS,
    payload: orders,
  };
}

export function loadOrderPending(pendingOrders) {
  return {
    type: LOAD_ORDER_PENDING,
    payload: pendingOrders,
  };
}

export function loadOrderHistory(orderHistory) {
  return {
    type: LOAD_ORDER_HISTORY,
    payload: orderHistory,
  };
}

export function loadExportOrderHistory(orderHistory) {
  return {
    type: EXPORT_LOAD_ORDER_HISTORY,
    payload: orderHistory,
  };
}

export function requestNetsuitePurchaseOrder() {
  return {
    type: REQUEST_NETSUITE_ORDER,
  };
}

export function loadNetsuitePurchaseOrder(netsuitePurchaseOrder) {
  return {
    type: LOAD_NETSUITE_ORDER,
    payload: netsuitePurchaseOrder,
  };
}

export function loadOrderHistoryByUser(orderHistory) {
  return {
    type: LOAD_ORDER_HISTORY_BY_USER,
    payload: orderHistory,
  };
}

export function startOrderUpdate() {
  return {
    type: START_ORDER_UPDATE,
  };
}

export function finishOrderUpdate() {
  return {
    type: FINISH_ORDER_UPDATE,
  };
}

export function replaceOrder(pendingOrder) {
  return {
    type: REPLACE_ORDER,
    payload: pendingOrder,
  };
}

export function replaceOrders(orders) {
  return {
    type: REPLACE_ORDERS,
    payload: orders,
  };
}

export function confDocEmailSent(data) {
  return {
    type: CONF_DOC_EMAIL_SENT,
    payload: data,
  };
}

export function setConfDocEmailLoading() {
  return {
    type: CONF_DOC_EMAIL_LOADING,
  };
}

export function setConfirmationDocLoading(documentType) {
  return {
    documentType,
    type: CONFIRMATION_DOC_LOADING,
  };
}

export function confDocGenerated(data) {
  return {
    type: CONF_DOC_GENERATED,
    documentType: get(data, 'documentType'),
    document: get(data, 'document', {}),
    documents: get(data, 'documents', {}),
  };
}

export function sendOrder(order, copies, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(order),
    }).then(response => {
        if (response.status === 400) {
          return response.json().then(json => {
            throw new Error(JSON.stringify(json));
          });
        }
        return Constants.handleErrors(response, dispatch, errorOrder);
      })
      .then(json => {
        if (copies > 1) {
          fetch(`${Constants.URL}order_management/purchase_orders/${json.id}/copies`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
              total_orders: Number(copies),
            }),
          })
            .then(response => Constants.handleErrors(response, dispatch, errorOrder))
            .then(jsons => {
              dispatch(loadFastOrders(jsons));
            })
            .catch(error => dispatch(errorOrder(error)));
        } else {
          dispatch(loadFastOrders([json]));
        }
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function sendSydneyOrdersInBulk(formattedOrders, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/bulk/sydney`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(formattedOrders),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(createdOrders => {
        const order = formattedOrders[0];
        dispatch(loadFastOrders(createdOrders));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function sendRecurringOrder(recurringOrder, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/recurring`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(recurringOrder),
    })
      .then(response => {
        if (response.status === 400) {
          return response.json().then(json => {
            throw new Error(JSON.stringify(json));
          });
        }
        return Constants.handleErrors(response, dispatch, errorOrder);
      })
      .then(json => {
        dispatch(loadFastOrders(json.purchase_orders));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

// This is a temp function that calls the existing Create PO API (for recurring orders)
// several times.
// TODO -- Create a bulk api for Chicago orders
export function sendRecurringOrders(recurringOrders, token) {
  return async dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    const errors = [];

    dispatch(startOrderCreation());

    const savedOrders = await recurringOrders.reduce(async (prevPromise, recurringOrder) => {
      let agg = [];
      try {
        agg = await prevPromise;
        const response = await fetch(`${Constants.URL}order_management/purchase_orders/recurring`, {
          method: 'POST',
          headers: header,
          body: JSON.stringify(recurringOrder),
        });
        const json = await Constants.handleErrors(response, dispatch, errorOrder);
        const POs = json.purchase_orders;
        return [...agg, ...POs];
      } catch (e) {
        errors.push(e);
        return agg;
      }
    }, []);

    // Handle errors
    if (errors.length) {
      return dispatch(errorOrder(errors[0]));
    }

    dispatch(finishOrderCreation());

    return dispatch(loadFastOrders(savedOrders));
  };
}

// This is a temp function that calls the existing Create PO API (for Chicago orders)
// several times.
// TODO -- Create a bulk api for Chicago orders
export function sendOrders(orderList, token) {
  return async dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    const errors = [];

    dispatch(startOrderCreation());

    const savedOrders = await orderList.reduce(async (prevPromise, order) => {
      let agg = [];
      try {
        agg = await prevPromise;
        const response = await fetch(`${Constants.URL}order_management/purchase_orders`, {
          method: 'POST',
          headers: header,
          body: JSON.stringify(order),
        });
        const newOrder = await Constants.handleErrors(response, dispatch, errorOrder);
        return [...agg, newOrder];
      } catch (e) {
        errors.push(e);
        return agg;
      }
    }, []);

    // Handle errors
    if (errors.length) {
      return dispatch(errorOrder(errors[0]));
    }

    dispatch(finishOrderCreation());
    return dispatch(loadFastOrders(savedOrders));
  };
}

export function removeOrder(order, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify({ status: 'cancelled' }),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(() => {
        const modifiedOrder = {
          ...order,
          status: 'cancelled',
        };
        dispatch(deleteOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function removeOrderDocument(order, documentId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}/files/${documentId}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(() => {
        const documents = get(order, 'documents') || [];
        const modifiedOrder = {
          ...order,
          edit_mode: true,
          loaded: true,
          documents: documents.filter(doc => doc.id !== documentId),
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function bulkOrders(orders, status, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/bulk`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(orders),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(jsons => {
        if (status === 'cancelled') {
          jsons.forEach(json => {
            dispatch(deleteOrder(json));
          });
        } else {
          jsons.forEach(json => {
            dispatch(replaceOrder(json));
          });
        }
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

// TODO, use PUT updateOrder for both chicago and syndey form
export function saveOrder(payload, orderId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    dispatch(startOrderUpdate());
    return fetch(`${Constants.URL}order_management/purchase_orders/${orderId}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(convertToSnakeCase(payload)),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const modifiedOrder = {
          ...json,
          orderUpdated: true,
        };
        dispatch(replaceOrder(modifiedOrder));
        dispatch(finishOrderUpdate());
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function draftOrder(payload, orderId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    dispatch(startOrderUpdate());
    return fetch(`${Constants.URL}order_management/purchase_orders/${orderId}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(convertToSnakeCase(payload)),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const modifiedOrder = {
          ...json,
          orderUpdated: true,
        };
        dispatch(replaceOrder(modifiedOrder));
        dispatch(finishOrderUpdate());
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function saveOrders(orderList) {
  return async dispatch => {
    try {
      const savedOrders = orderList.map(async order => {
        // Remove id from order payload
        const patchableOrder = { ...order };
        delete patchableOrder.id;
        // If missing id, throw an error
        if (!order.id) throw new Error('Save Order Error: Missing order id');
        // TODO - Create a bulk patch endpoint that can accommodate both Chicago and Sydney order types
        // As of now, the existing bulk apis are for one or the other.
        const response = await api.patch(
          `/order_management/purchase_orders/${order.id}`,
          convertToSnakeCase(patchableOrder)
        );
        const json = await Constants.handleErrors(response, dispatch, errorOrder);
        return {
          ...json,
          orderUpdated: true,
        };
      });
      dispatch(replaceOrders(savedOrders));
    } catch (error) {
      dispatch(errorOrder(error));
    }
  };
}

export function addLine(order, line, token) {
  return dispatch => {
    const lineToSend = {
      input_product_uid: line.input_product_uid,
      number_of_bins: line.number_of_bins,
      price_per_unit: line.price_per_unit,
      expected_production_date: line.expected_production_date,
      buy_quantity: line.buy_quantity,
      sell_quantity: line.sell_quantity,
    };
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}/lines`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify([lineToSend]),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const modifiedOrder = {
          ...order,
          lines: json,
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function assignVoyageLegToPO(order, voyageLegId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}/voyage_legs/${voyageLegId}`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify({}),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const modifiedOrder = {
          ...order,
          voyage_legs: json,
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function removeMarks(order, marks, token) {
  const marksCopy = cloneDeep(marks);
  const markIds = marksCopy.reduce((agg, mark) => {
    agg.push(mark.id);
    return agg;
  }, []);
  const portMarks = [...get(order, 'bill_of_lading.shipment_port_marks', [])];
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}/bill_of_lading/marks`, {
      method: 'DELETE',
      headers: header,
      body: JSON.stringify({
        shipment_port_mark_ids: markIds,
      }),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(() => {
        const updatedPortMarks = portMarks.filter(mark => !marksCopy.includes(mark));

        const modifiedOrder = {
          ...order,
          bill_of_lading: {
            ...order.bill_of_lading,
            shipment_port_marks: updatedPortMarks,
          },
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function removeVoyageLegFromPO(order, voyageLegId, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}/voyage_legs/${voyageLegId}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(() => {
        const modifiedOrder = {
          ...order,
          voyage_legs: order.voyage_legs.filter(el => el.id !== voyageLegId),
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function addVoyageLeg(order, voyageLeg, token) {
  const header = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  });
  const payload = {
    vessel_name: voyageLeg.vessel_name,
    voyage_number: voyageLeg.voyage_number,
    shipping_line_id: voyageLeg.shipping_line_id,
    origin_port_id: voyageLeg.origin_port_id,
    destination_port_id: voyageLeg.destination_port_id,
    etd: voyageLeg.etd,
    eta: voyageLeg.eta,
  };

  return dispatch => {
    return fetch(VL_BASE_URL, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder()))
      .then(json => {
        dispatch(assignVoyageLegToPO(order, json.id, token));
      })
      .catch(error => {
        dispatch(errorOrder(error));
      });
  };
}

// temporary solution - TODO: unify it with us domestic
export function addInternationalLine({ purchaseOrderId, line, token }) {
  const lineToSend = {
    input_product_uid: line.inputProductUid,
    buy_price_per_unit: parseFloat(line.buyPricePerUnit),
    sell_price_per_unit: parseFloat(line.sellPricePerUnit),
    buy_quantity: Number(line.buyQuantity),
    sell_quantity: Number(line.sellQuantity),
    chilled: line.chilled,
    frozen: line.frozen,
    specifications: line.specifications,
    ...(line.expectedProductionDate && { expected_production_date: line.expectedProductionDate }),
    purchase_order_id: purchaseOrderId,
    packer_plant_id: get(line, 'packerPlantId', null),
    package_count: get(line, 'packageCount', null),
    packer_ingredient_description: get(line, 'packerIngredientDescription'),
    hedge_items: convertToSnakeCase(get(line, 'hedgeItems', null)),
    fob_price_per_unit: parseFloat(line.fobPricePerUnit),
    price_per_unit: parseFloat(line.pricePerUnit),
    group_housed_premium_rate: parseFloat(line.groupHousedPremiumRate),
    price_agreement_id : line.priceAgreementId,
    price_type: line.priceType,
    order_type: line.orderType,
    order_type_notes: line.orderTypeNotes,
    standing: line.standing,
  };

  if (!isNaN(Number(line.recvQuantity))) {
    lineToSend.recv_quantity = Number(line.recvQuantity);
  }

  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${purchaseOrderId}/lines`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify([lineToSend]),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const addedLine = json[json.length - 1];
        dispatch(addLineSuccess(addedLine.id));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function addOrUpdateLineProductionDetails(purchaseOrderLineId, productionDetails, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_order_line/${purchaseOrderLineId}/production_details`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(productionDetails),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const modifiedOrder = {
          ...json,
          orderUpdated: true,
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function deleteLineProductionDetails(purchaseOrderLineId, toDeleteProductionDetailIds, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_order_line/${purchaseOrderLineId}/production_details`, {
      method: 'DELETE',
      headers: header,
      body: JSON.stringify(toDeleteProductionDetailIds),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const modifiedOrder = {
          ...json,
          orderUpdated: true,
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function addOrUpdateLineAssociatedModel({ purchaseOrderLineId, data, modelName, paramsObject, token }) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    let params = '';
    if (paramsObject) params = new URLSearchParams(paramsObject).toString();
    return fetch(`${Constants.URL}order_management/purchase_order_line/${purchaseOrderLineId}/${modelName}?${params}`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const modifiedOrder = {
          ...json,
          orderUpdated: true,
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function deleteLineAssociatedModel({ purchaseOrderLineId, toDeleteIds, modelName, paramsObject, token }) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    let params = '';
    if (paramsObject) params = new URLSearchParams(paramsObject).toString();
    return fetch(`${Constants.URL}order_management/purchase_order_line/${purchaseOrderLineId}/${modelName}?${params}`, {
      method: 'DELETE',
      headers: header,
      body: JSON.stringify(toDeleteIds),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const modifiedOrder = {
          ...json,
          orderUpdated: true,
        };
        dispatch(replaceOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function addOrUpdatePurchaseOrderAssociatedModel({ purchaseOrderId, data, modelName, token }) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${purchaseOrderId}/${modelName}`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function deletePurchaseOrderAssociatedModel({ purchaseOrderId, toDeleteIds, modelName, token }) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${purchaseOrderId}/${modelName}`, {
      method: 'DELETE',
      headers: header,
      body: JSON.stringify(toDeleteIds),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .catch(error => dispatch(errorOrder(error)));
  };
}

// Expect payload to be snake-cased
export function editInternationalLine({ purchaseOrderId, line, token }) {
  const lineToSend = {
    input_product_uid: line.inputProductUid,
    buy_price_per_unit: parseFloat(line.buyPricePerUnit),
    sell_price_per_unit: parseFloat(line.sellPricePerUnit),
    group_housed_premium_rate:parseFloat(line.groupHousedPremiumRate),
    price_agreement_id:line.priceAgreementId,
    buy_quantity: Number(line.buyQuantity),
    sell_quantity: Number(line.sellQuantity),
    chilled: line.chilled,
    frozen: line.frozen,
    specifications: line.specifications,
    ...(line.expectedProductionDate && { expected_production_date: line.expectedProductionDate }),
    ...(line.packerPlantId && { packer_plant_id: line.packerPlantId }),
    active: line.active,
    package_count: line.packageCount,
    package_type: line.packageType,
    packer_ingredient_description: line.packerIngredientDescription,
    hedge_items: convertToSnakeCase(get(line, 'hedgeItems', null)),
    fob_price_per_unit: parseFloat(line.fobPricePerUnit),
    price_per_unit: parseFloat(line.pricePerUnit),
    order_type: line.orderType,
    price_type: line.priceType,
    order_type_notes: line.orderTypeNotes,
    standing: line.standing,
    estimated: line.estimated,
    ...(line.contractStartDate && {
      contract_start_date: line.priceType === 'formula' ? null : line.contractStartDate,
    }),
    ...(line.contractEndDate && { contract_end_date: line.priceType === 'formula' ? null : line.contractEndDate }),
    ...(line.formulaName && { formula_name: line.priceType === 'contract' ? null : line.formulaName }),
    ...(line.formulaMarket && { formula_market: line.priceType === 'contract' ? null : line.formulaMarket }),
    ...(line.formulaDay && { formula_day: line.priceType === 'contract' ? null : line.formulaDay }),
    ...(line.formulaDaysAveraged && {
      formula_days_averaged: line.priceType === 'contract' ? null : line.formulaDaysAveraged,
    }),
    ...(line.formulaBasis && { formula_basis: line.priceType === 'contract' ? null : line.formulaBasis }),
    ...(!line.clResults.every(element => element.value === null) && {
      cl_results: line.clResults.map(i => {
        return { clvalue: i.clvalue, ...(i.id && { id: i.id }) };
      }),
    }),
    ...(!line.clResults.every(element => element.value === null) && { op_cl_type: line.opClType || 'bin' }),
    ...{ op_average_third_party_cl_result: line.opAverageThirdPartyClResult },
    ...{ op_testresult: line.opTestResult },
    ...(!line.temperatures.every(element => element.value === null) && {
      temperatures: line.temperatures.map(i => {
        return { temperature: i.value, ...(i.id && { id: i.id }) };
      }),
    }),
    ...(!line.temperatures.every(element => element.value === null) && { op_temp_type: line.opTempType || 'bin' }),
  };

  if (!isNaN(Number(line.recvQuantity))) {
    lineToSend.recv_quantity = Number(line.recvQuantity);
  }

  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${purchaseOrderId}/lines/${line.id}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(lineToSend),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function editLine(order, line, token) {
  return dispatch => {
    const lineToSend = {
      input_product_uid: line.input_product_uid,
      number_of_bins: line.number_of_bins,
      price_per_unit: line.price_per_unit,
      expected_production_date: line.expected_production_date,
      buy_quantity: line.buy_quantity,
      sell_quantity: line.sell_quantity,
    };
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}/lines/${line.id}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(lineToSend),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .catch(error => dispatch(errorOrder(error)));
  };
}

/* not used on international orders as the line isn't actually deleted
 status update can be passed in editInternationalOrder */
export function deleteLine(order, line, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}/lines/${line.id}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify({ active: false }),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function submitOrder(order, orderId, statusChange, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${orderId}`, {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(order),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        if (statusChange) {
          const modifiedOrder = {
            ...json,
            fastLoaded: true,
          };
          dispatch(loadOrder(modifiedOrder));
        } else {
          dispatch(replaceOrder(json));
        }
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

// for chicago orders
export function updateOrder(order, orderId, statusChange, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${orderId}`, {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(convertToSnakeCase(order)),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        if (statusChange) {
          const modifiedOrder = {
            ...json,
            fastLoaded: true,
          };
          dispatch(loadOrder(modifiedOrder));
        } else {
          dispatch(replaceOrder(json));
        }
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function cancelOrder(order, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/${order.id}`, {
      method: 'PUT',
      headers: header,
      body: JSON.stringify({ status: 'cancelled' }),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(() => {
        const modifiedOrder = {
          ...order,
          status: 'cancelled',
        };
        dispatch(deleteOrder(modifiedOrder));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function addDocumentToOrder(order, payload, token) {
  return dispatch => {
    const formData = new FormData();
    formData.append('file', payload.file);
    const header = new Headers({ Authorization: `Token ${token}` });
    return fetch(
      `${Constants.URL}order_management/purchase_orders/${order.id}/files?doc_type=${payload.docType}&saveToDB=${payload.saveToDB}`,
      {
        method: 'POST',
        headers: header,
        body: formData,
      }
    )
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        const orderCopy = {
          ...order,
          edit_mode: true,
          loaded: true,
          orderUpdated: true,
          documents: [...order.documents, json],
        };

        // adding doc is not end of order update functionality
        dispatch(replaceOrder(orderCopy));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function generateConfirmationDocument(payload, documentType, token) {
  const endpointName = documentType === Constants.PO_DOC_TYPES.SALE ? 'sale' : 'purchase';

  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/confirmation_of_${endpointName}`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(payload),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(({ document, documents }) => dispatch(confDocGenerated({ documents, document, documentType })))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function sendEmailWithConfDoc(payload, documentType, token) {
  const endpointName = documentType === Constants.PO_DOC_TYPES.SALE ? 'sale' : 'purchase';

  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}order_management/purchase_orders/confirmation_of_${endpointName}/mail`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(payload),
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(confDocEmailSent(json)))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetchOrderPending(token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const queryString =
      `${Constants.URL}order_management/purchase_orders?` +
      `status=pending&ordered_start_date=${startDate}&ordered_end_date=${endDate}`;

    return fetch(queryString, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(loadOrderPending(json)))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetchOrderHistory(parameters, token, forExport) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });

    // TODO - Legacy query string logic.  Will remove after I do some more testing.
    // let queryString = '';

    // for (let parameter in parameters) {
    //   if (parameters[parameter] && parameters[parameter] !== '') {
    //     if (queryString !== '?') {
    //       queryString += '&';
    //     }
    //     queryString += parameter + '=' + parameters[parameter];
    //   }
    // }

    const cleanParams = Object.entries(parameters).reduce((agg, [key, value]) => {
      if (isNil(value) || value === '') {
        return agg;
      }
      let param = value;
      if (Array.isArray(value)) {
        param = value.map(option => get(option, 'value') || option);
      }
      if (typeof value === 'string' && value.indexOf(',') > -1) {
        param = value.split(',');
      }

      return {
        ...agg,
        [key]: param,
      };
    }, {});

    // prevent missing data for export
    if (forExport) {
      delete cleanParams.page;
      cleanParams.limit = 15000;
    }

    const queryString = qs.stringify(cleanParams, { arrayFormat: 'comma' });

    dispatch(startSearch());

    return fetch(`${Constants.URL}order_management/purchase_orders?noload=documents&${queryString}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(forExport ? loadExportOrderHistory(json) : loadOrderHistory(json)))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetchExportHistory(parameters, token) {
  const header = new Headers({ Authorization: `Token ${token}` });
  const cleanParams = Object.entries(parameters).reduce((agg, [key, value]) => {
    if (isNil(value) || value === '') {
      return agg;
    }
    let param = value;
    if (Array.isArray(value)) {
      param = value.map(option => get(option, 'value') || option);
    }
    if (typeof value === 'string' && value.indexOf(',') > -1) {
      param = value.split(',');
    }

    return {
      ...agg,
      [key]: param,
    };
  }, {});

  const queryString = qs.stringify(cleanParams, { arrayFormat: 'comma' });
  return fetch(`${Constants.URL}order_management/purchase_orders?noload=documents&export=true&${queryString}`, {
    method: 'GET',
    headers: header,
  });
}

export function fetchNetsuitePurchaseOrder(purchaseOrderID, token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    dispatch(requestNetsuitePurchaseOrder());

    return fetch(`${Constants.URL}order_management/purchase_orders/${purchaseOrderID}/netsuite_all`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        dispatch(loadNetsuitePurchaseOrder(json));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function fetchOrderHistoryByUser(token, userID) {
  const startDate = moment().subtract(60, 'days').format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');
  const queryString =
    `${Constants.URL}order_management/purchase_orders?` +
    `user_id=${userID}&status=ordered&ordered_start_date=${startDate}&ordered_end_date=${endDate}`;
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    return fetch(queryString, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => dispatch(loadOrderHistoryByUser(json)))
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function getPurchaseOrderById(token, orderId) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });

    return fetch(`${Constants.URL}order_management/purchase_orders/${orderId}/netsuite_all`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(json => {
        dispatch(loadEditOrder(json));
      })
      .catch(error => dispatch(errorOrder(error)));
  };
}

export function autoResolveTasksForOrder(token, poId, grinderPoId) {
  return async dispatch => {
    const endpointUrl = `${Constants.URL}exceptions-scan?should_return_early=true&po_id=${poId}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    return fetch(endpointUrl, {
      method: 'POST',
      headers,
      body: '{}',
    })
      .then(response => Constants.handleErrors(response, dispatch, errorOrder))
      .then(() => {
        dispatch(fetchDailyTaskDetails(token, grinderPoId));
      })
      .catch(error => {
        dispatch(errorOrder(error));
      });
  };
}
