import { Alert, AlertDescription, AlertIcon, Box, FormLabel, IconButton, Text } from '@chakra-ui/react';
import { Field } from 'formik';
import get from 'lodash/get';
import React from 'react';
import DateField from './DateField';
import MultiNestedField from './MultiNestedField';
import NestedField from './NestedField';
import ReferenceSelectField from './ReferenceSelectField';
import SimpleReferenceSelectField from './SimpleReferenceSelectField';
import Tooltip from '../../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

export const FieldErrorMessage = ({ errors, fieldName }) => {
  const error = get(errors, fieldName);
  if (!error) return false;
  return (
    <Box width="234px">
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    </Box>
  );
};

const FieldRender = ({ errors, field, referencedEntities, thisEntity, prefix = null, value = null, setSignatureUpload }) => {
  let elements = [];
  const fieldName = prefix ? `${prefix}.${field.name}` : field.name;
  switch (field.referenceType) {
    case 'multi-select':
    case 'select':
      elements = (
        <Box key={fieldName}>
          <FormLabel htmlFor={fieldName}>
            {field.displayName}&nbsp;
            {field.tooltip && (
              <Tooltip content={field.tooltip} placement="right">
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  icon={<IoInformationCircleOutline size="14px" />}
                  variant="unstyled"
                />
              </Tooltip>
            )}
          </FormLabel>
          <Field
            as={ReferenceSelectField}
            name={fieldName}
            SubType={field.SubType}
            containerStyles={{
              padding: '0px',
            }}
            controlStyles={{
              maxHeight: '225px',
              width: '234px',
            }}
            referencedEntity={referencedEntities[field.referenceEntityType]}
            referenceEntityType={field.referenceEntityType}
            referenceEntitySubType={field.referenceEntitySubType}
            referenceType={field.referenceType}
          />
          <FieldErrorMessage errors={errors} fieldName={fieldName} />
        </Box>
      );
      break;
    case 'nested':
      elements = (
        <NestedField
          errors={errors}
          field={field}
          referencedEntities={referencedEntities}
          prefix={fieldName}
          value={value}
        />
      );
      break;
    case 'multi-nested':
      elements = (
        <MultiNestedField
          errors={errors}
          field={field}
          referencedEntities={referencedEntities}
          thisEntity={thisEntity}
          prefix={fieldName}
        />
      );
      break;
    default:
      switch (field.dataType) {
        case 'simple-reference':
          const options = referencedEntities[field.referenceEntityType].entities.map(item => {
            return field.code ? { label: item.name, value: item.code } : { label: item.name, value: item.name };
          });
          elements = (
            <Box key={fieldName}>
              <FormLabel htmlFor={fieldName}>
                {field.displayName}&nbsp;
                {field.tooltip && (
                  <Tooltip content={field.tooltip} placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                )}
              </FormLabel>
              <Field
                as={SimpleReferenceSelectField}
                fieldName={fieldName}
                options={options}
                name={fieldName}
                id={fieldName}
              />
              <FieldErrorMessage errors={errors} fieldName={fieldName} />
            </Box>
          );
          break;
        case 'simple-select':
          elements = (
            <Box key={fieldName}>
              <FormLabel htmlFor={fieldName}>
                {field.displayName}&nbsp;
                {field.tooltip && (
                  <Tooltip content={field.tooltip} placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                )}
              </FormLabel>
              <Field
                as={SimpleReferenceSelectField}
                fieldName={fieldName}
                name={fieldName}
                id={fieldName}
                className="form-control"
                options={field.options}
                controlStyles={{
                  width: '234px',
                }}
                isClearable={false}
              />
              <FieldErrorMessage errors={errors} fieldName={fieldName} />
            </Box>
          );
          break;
        case 'component':
          if (thisEntity || field.includeInAddNew) {
            const Component = field.component;
            const props = {};
            props[field.propName] = thisEntity;
            props.containerStyles = {
              padding: '0px',
            };
            props.controlStyles = {
              maxHeight: '225px',
              width: '234px',
              overflow: 'scroll',
            };
            elements = (
              <>
                <Component {...props} errors={errors} fieldName={fieldName} setSignatureUpload={setSignatureUpload}/>
                <FieldErrorMessage errors={errors} fieldName={fieldName} />
              </>
            );
          }
          break;
        case 'date':
          elements = (
            <Box key={fieldName} width="234px">
              <FormLabel htmlFor={fieldName}>
                {field.displayName}&nbsp;
                {field.tooltip && (
                  <Tooltip content={field.tooltip} placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                )}
              </FormLabel>
              <Field as={DateField} name={fieldName} className="form-control" />
              <FieldErrorMessage errors={errors} fieldName={fieldName} />
            </Box>
          );
          break;
        case 'longtext':
          elements = (
            <Box key={fieldName}>
              <FormLabel htmlFor={fieldName}>
                {field.displayName}&nbsp;
                {field.tooltip && (
                  <Tooltip content={field.tooltip} placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                )}
              </FormLabel>
              <Field
                as="textarea"
                name={fieldName}
                id={fieldName}
                className="form-control"
                style={{
                  width: '234px',
                  height: '225px',
                }}
                rows="10"
                value={value == null ? '' : value}
              />
              <FieldErrorMessage errors={errors} fieldName={fieldName} />
            </Box>
          );
          break;
        case 'sectionText':
          elements = (
            <Box width="100%" marginTop="40px !important">
              <Text as="p" fontSize="15px" fontWeight="bold" color="accent.one.default">
                {field.displayName}&nbsp;
                {field.tooltip && (
                  <Tooltip content={field.tooltip} placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                )}
              </Text>
            </Box>
          );
          break;
        case 'hidden':
          elements = '';
          break;
        case 'boolean':
          const containBlankOption = field.containBlankOption !== undefined ? field.containBlankOption : true;
          elements =
            field.name === 'delisted' ? (
              // hide delisted since it is already in modal footer
              <Box display="none">
                <Box marginBottom="14px">
                  <Text as="p" fontSize="15px" fontWeight="bold" color="accent.one.default">
                    Active
                  </Text>
                </Box>
                <Box key={fieldName}>
                  <FormLabel htmlFor={fieldName}>
                    {field.displayName}&nbsp;
                    {field.tooltip && (
                      <Tooltip content={field.tooltip} placement="right">
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          icon={<IoInformationCircleOutline size="14px" />}
                          variant="unstyled"
                        />
                      </Tooltip>
                    )}
                  </FormLabel>
                  <Field
                    as="select"
                    name={fieldName}
                    id={fieldName}
                    className="form-control"
                    style={{
                      width: '234px',
                    }}
                  >
                    {containBlankOption && <option value="" />}
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </Field>
                  <FieldErrorMessage errors={errors} fieldName={fieldName} />
                </Box>
              </Box>
            ) : (
              <Box key={fieldName}>
                <FormLabel htmlFor={fieldName}>
                  {field.displayName}&nbsp;
                  {field.tooltip && (
                    <Tooltip content={field.tooltip} placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                  )}
                </FormLabel>
                <Field
                  as={SimpleReferenceSelectField}
                  fieldName={fieldName}
                  name={fieldName}
                  id={fieldName}
                  className="form-control"
                  options={[
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ]}
                />
                <FieldErrorMessage errors={errors} fieldName={fieldName} />
              </Box>
            );
          break;
        case 'hidden':
          elements = <Field type={field.dataType} name={fieldName} value={value || ''} />;
          break;
        default:
          elements = (
            <Box key={fieldName}>
              <FormLabel htmlFor={fieldName}>
                {field.displayName}&nbsp;
                {field.tooltip && (
                  <Tooltip content={field.tooltip} placement="right">
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      icon={<IoInformationCircleOutline size="14px" />}
                      variant="unstyled"
                    />
                  </Tooltip>
                )}
              </FormLabel>
              <Field
                type={field.dataType}
                name={fieldName}
                id={fieldName}
                className="form-control"
                style={{
                  width: '234px',
                }}
                value={value == null ? '' : value}
              />
              <FieldErrorMessage errors={errors} fieldName={fieldName} />
            </Box>
          );
          break;
      }
      break;
  }
  return elements;
};

export default FieldRender;
