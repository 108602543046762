import React, { useState } from 'react';
import { Box, IconButton, Link, Text } from '@chakra-ui/react';
import { Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { EditIcon } from '@chakra-ui/icons';
import UpdateContactInfo from './UpdateContactInfo';

const ContactInfo = ({ data, estId, updateList }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  return (
    <Box marginBottom="15px">
      <Box display="flex" justifyContent="space-between">
        <Text color="rgba(0, 0, 0, 0.87)" fontSize="14px" fontWeight="600" lineHeight="1.43" marginBottom="15px">
          Contact Information
        </Text>
        <Tooltip title="Update Contact Information">
          <IconButton variant="solid" colorScheme="actionSecondary" onClick={() => setIsOpenPopup(true)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Table
        pagination={{
          pageSize: 10,
          current: 1,
          style: { display: 'none' },
        }}
        rowClassName="food-safety-table-row"
        className="food-safety-table"
        showHeader={false}
        dataSource={data.filter((item) => item.fullname || item.mobile || item.after_hours || item.email || item.position) || []}
        columns={[
          {
            dataIndex: 'info_type',
            key: 'info_type',
          },
          {
            dataIndex: 'fullname',
            key: 'fullname',
          },
          {
            dataIndex: 'mobile',
            key: 'mobile',
          },
          {
            dataIndex: 'email',
            key: 'email',
            render: (email, record) =>
              record?.info_type === 'After Hours Contact' ? (
                `${record?.position} (${record?.after_hours})`
              ) : (
                <Link
                  _hover={{ color: 'inherit', textDecoration: 'underline' }}
                  _active={{ color: 'inherit', textDecoration: 'underline' }}
                  textDecoration="underline"
                  href={`mailto:${email}`}
                >
                  {email}
                </Link>
              ),
          },
        ]}
      />

      {isOpenPopup && (
        <UpdateContactInfo
          isOpenPopup={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
          editItem={data}
          estId={estId}
          updateList={updateList}
        />
      )}
    </Box>
  );
};

ContactInfo.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      info_type: PropTypes.string,
      fullname: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  estId: PropTypes.number,
  updateList: PropTypes.func,
};

export default ContactInfo;
