import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';

// Local deps
import {
  bulkOrders,
  fetchNetsuitePurchaseOrder,
  fetchOrderHistory,
  resetModalOrder,
  submitOrder,
} from '../../../actions/actions_orders';

import { commify, formatMonetaryValue } from '../../../functions';
import { findForeignItem, convertToCamelCase } from '../../../helpers';
import { fetchEntitiesData } from '../../../slices/masterData/entityManagerSlice';
import { checkIfChicagoForm } from '../../lib/PurchaseOrderFunctions';

import './OrderTable.scss';
import { Box, Button,Checkbox} from '@chakra-ui/react';
import { isArray } from 'lodash';
import { fetchEndUsers } from '../../../actions/actions_end_user';
import { getInternalPoLabel } from '../../../utils';
import { connect } from 'react-redux';

import Tooltip from '../../quality/_components/Tooltip';
import { MdLockOutline } from 'react-icons/md';

function getPosition(element) {
  let xPosition = 0;
  let yPosition = 0;

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }

  return { x: xPosition, y: yPosition };
}

class POGridOrderTable extends Component {
  constructor(props) {
    super(props);

    this.fixedHeaderTable = element => {
      this.headerTable = element;
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      addDocumentOpened: false,
      date_range: [moment().subtract(2, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      fixedHeader: false,
      headerWidth: 0,
      isChicagoForm: null,
      selectedOrders: [],
      sortTable: false,
      upload_doc_order: false,
    };
  }

  static propTypes = {
    current_order: PropTypes.instanceOf(Object),
    dispatch: PropTypes.func,
    grinders: PropTypes.arrayOf(PropTypes.object),
    all_grinders: PropTypes.arrayOf(PropTypes.object),
    all_input_products: PropTypes.arrayOf(PropTypes.object),
    packer_plants: PropTypes.arrayOf(PropTypes.object),
    all_packer_plants: PropTypes.arrayOf(PropTypes.object),
    packers: PropTypes.arrayOf(PropTypes.object),
    setLoading: PropTypes.func,
    token: PropTypes.string,
    endUsers: PropTypes.arrayOf(PropTypes.object),
    configs: PropTypes.arrayOf(PropTypes.object),
  };

  getQueryParam(param, defaultValue = null) {
    return new URLSearchParams(location.search).get(param) || defaultValue;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
    if (this.props.endUsers.length === 0) {
      this.props.dispatch(fetchEndUsers(this.props.token));
    }
    if (location.search) {
      const autoOpenPoCardPurchaseOrderId = this.getQueryParam('auto_open_po_card_purchase_order_id');
      if (autoOpenPoCardPurchaseOrderId) {
        this.editOrder(autoOpenPoCardPurchaseOrderId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { current_order: currentOrder } = this.props;

    if (currentOrder && currentOrder.id !== get(prevProps, 'current_order.id')) {
      const isChicagoForm = checkIfChicagoForm({
        purchasingOffice: currentOrder.purchasing_office,
        invoicingOffice: currentOrder.invoicing_office,
      });
      this.setState({
        isChicagoForm,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  sortTable = (a, b) => {
    const { packer_plants: packerPlants, grinders, all_input_products: inputProducts } = this.props;
    const { sortTable } = this.state;
    // If reverse string exists, then sort the other direction
    let thingA = false;
    let thingB = false;

    if (sortTable.indexOf('packer') > -1) {
      packerPlants.forEach(pp => {
        if (pp.id === a.packer_plant_id) {
          thingA = pp;
        }
        if (pp.id === b.packer_plant_id) {
          thingB = pp;
        }
      });
    }
    if (sortTable.indexOf('grinder') > -1) {
      grinders.forEach(grinder => {
        if (grinder.uid === a.grinder_uid) {
          thingA = grinder;
        }
        if (grinder.uid === b.grinder_uid) {
          thingB = grinder;
        }
      });
    }
    if (sortTable.indexOf('input_product') > -1) {
      inputProducts.forEach(ip => {
        if (ip.uid === a.lines[0].input_product_uid) {
          thingA = ip;
        }
        if (ip.uid === b.lines[0].input_product_uid) {
          thingB = ip;
        }
      });
    }
    //Week Ending Date Sorting ASC/DESC
    if (sortTable === 'week_ending_date' || sortTable === 'week_ending_date_reverse') {
      const getWeekEndingDate = (date) => {
          const deliveryDate = new Date(date);
          const dayOfWeek = deliveryDate.getDay();
          const daysUntilSunday = 7 - dayOfWeek;
          const weekEndingDate = new Date(deliveryDate);
          weekEndingDate.setDate(deliveryDate.getDate() + daysUntilSunday);
          return weekEndingDate;
      };    
      const dateA = getWeekEndingDate(a.delivery_date);
      const dateB = getWeekEndingDate(b.delivery_date);      
      return sortTable === 'week_ending_date'
          ? dateA - dateB
          : dateB - dateA;
    }
    // Order Type Sorting ASC/DESC
    if (sortTable === 'order_type' || sortTable === 'order_type_reverse') {
      const orderTypeA = a.lines?.[0]?.price_type || '';
      const orderTypeB = b.lines?.[0]?.price_type || '';  
      return sortTable === 'order_type'
          ? orderTypeA.localeCompare(orderTypeB)
          : orderTypeB.localeCompare(orderTypeA);
    }
    if (sortTable.indexOf('_reverse') > -1) {
      const table = sortTable.replace('_reverse', '');
      // Descending order sorting sell price per unit
      if (table === 'sell_price_per_unit') {
        const sellPriceA = a.lines?.[0]?.sell_price_per_unit || 0; // Default to 0 if not found
        const sellPriceB = b.lines?.[0]?.sell_price_per_unit || 0;
        return sellPriceB - sellPriceA; // Descending order
      }    
      // Descending order sorting quantity
      if(table === 'quantity'){
        return b.lines[0].buy_quantity - a.lines[0].buy_quantity
      }
      if(table === 'buy_price_per_unit'){
        return b.lines[0].buy_price_per_unit - a.lines[0].buy_price_per_unit
      }
      if(table === 'price_per_unit'){
        return b.lines[0].price_per_unit - a.lines[0].price_per_unit
      }
      // Descending order sorting sell price per unit
      if(table === 'day') {
        // Convert deliveryDate to day index (0 = Sunday, 6 = Saturday)
        const dayA = new Date(a.delivery_date).getDay();
        const dayB = new Date(b.delivery_date).getDay();
        return dayB - dayA;
      }
      if (table === 'delivery_date') {
        return b.delivery_date - a.delivery_date;
      }
      if (table === 'packer' || table === 'grinder' || table === 'input_product') {
        return thingB.name.localeCompare(thingA.name);
      }

      const val1 = a[table] ? a[table] : 0;
      const val2 = b[table] ? b[table] : 0;
      if (!isNaN(Number(val1)) && sortTable.indexOf('date') === -1) {
        return val2 - val1;
      }

      return val2.localeCompare(val1);
    }
    // DAY Asc Order Sorting 
    if(sortTable === 'day') {
      // Convert deliveryDate to day index (0 = Sunday, 6 = Saturday)
      const dayA = new Date(a.delivery_date).getDay();
      const dayB = new Date(b.delivery_date).getDay();
      return dayA - dayB;
    }    
    //Quantity ascending order
    if (sortTable === 'quantity') {
      return a.lines[0].buy_quantity - b.lines[0].buy_quantity;
    }
    if(sortTable === 'buy_price_per_unit') {
      return a.lines[0].buy_price_per_unit - b.lines[0].buy_price_per_unit;
    }
    //Price per unit ascending order
    if(sortTable === 'price_per_unit') {
      return a.lines[0].price_per_unit - b.lines[0].price_per_unit;
    }
    // Sell price per unit asc order
    if (sortTable === 'sell_price_per_unit') {
      const sellPriceA = a.lines?.[0]?.sell_price_per_unit || 0; // Default to 0 if not found
      const sellPriceB = b.lines?.[0]?.sell_price_per_unit || 0;
      return sellPriceA - sellPriceB; // Ascending order
    }

    if (sortTable === 'packer' || sortTable === 'grinder' || sortTable === 'input_product') {
      return thingA.name.localeCompare(thingB.name);
    }
    const val1 = a[sortTable] ? a[sortTable] : 0;
    const val2 = b[sortTable] ? b[sortTable] : 0;
    if (!isNaN(Number(val1)) && sortTable.indexOf('date') === -1) {
      return val1 - val2;
    }
    if (sortTable.indexOf('date') > -1) {
      return moment.utc(a[sortTable]).diff(moment.utc(b[sortTable]));
    }
    return val1.localeCompare(val2);
  };

  handleScroll() {
    if (!this.headerTable) {
      return;
    }

    const positionFromTop = getPosition(this.headerTable).y;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const totalHeightFromTop = this.headerTable.offsetHeight + positionFromTop;

    if (scrollTop >= positionFromTop && scrollTop <= totalHeightFromTop) {
      this.setState({ fixedHeader: true });
    } else {
      this.setState({
        fixedHeader: false,
        headerWidth: this.headerTable.offsetWidth,
      });
    }
  }

  selectCurrentModalDetail = () => {
    const { dispatch } = this.props;
    dispatch(resetModalOrder());
  };

  editOrder = purchaseOrderID => {
    const { dispatch, token } = this.props;
    dispatch(fetchNetsuitePurchaseOrder(purchaseOrderID, token));
    dispatch(fetchEntitiesData());
  };

  getOrderHistory() {
    const { dispatch, setLoading, token } = this.props;
    const { date_range: dateRange } = this.state;
    dispatch(fetchOrderHistory(dateRange[0], dateRange[1], token));
    setLoading();
  }

  submitOrder(order) {
    const { dispatch, token } = this.props;
    const currentOrder = JSON.parse(JSON.stringify(order));
    currentOrder.status = 'ordered';
    dispatch(submitOrder(currentOrder, true, token));
    dispatch(resetModalOrder());
  }

  selectOrder(order, e) {
    const { selectedOrders } = this.state;
    let newSelectedOrders = [];

    if (e.target.checked) {
      newSelectedOrders = JSON.parse(JSON.stringify(selectedOrders));
      newSelectedOrders.push(order);
    } else {
      selectedOrders.forEach(thisOrder => {
        if (thisOrder.uid !== order.uid) {
          newSelectedOrders.push(order);
        }
      });
    }
    this.setState({ selectedOrders: newSelectedOrders });
  }

  submitOrders() {
    const { dispatch, token } = this.props;
    const { selectedOrders } = this.state;
    dispatch(bulkOrders(selectedOrders, 'ordered', token));
    this.setState({ selectedOrders: [] });
  }

  deleteOrders() {
    const { dispatch, token } = this.props;
    const { selectedOrders } = this.state;
    dispatch(bulkOrders(selectedOrders, 'cancelled', token));
    this.setState({ selectedOrders: [] });
  }

  changeAttachmentModalState = order => {
    const { addDocumentOpened } = this.state;
    this.setState({
      addDocumentOpened: !addDocumentOpened,
      upload_doc_order: order,
    });
    formatProductionDates;
  };
  
  formatProductionDates(data) {
    const currentYear = new Date().getFullYear(); // Get the current year

    // Group data by year and month
    const groupedByYearAndMonth = data.reduce((acc, item) => {
      const date = new Date(item.productionDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are 0-indexed
      const yearMonth = `${year}/${month.toString().padStart(2, '0')}`;

      if (!acc[yearMonth]) acc[yearMonth] = [];
      acc[yearMonth].push(date.getDate()); // Only store the day of the month
      return acc;
    }, {});

    // Format the grouped data
    return Object.keys(groupedByYearAndMonth)
      .map(yearMonth => {
        const [year, month] = yearMonth.split('/');
        const days = groupedByYearAndMonth[yearMonth].sort((a, b) => b - a); // Sort days descending
        const formattedDays = days.join(',');

        // Include year only if it's not the current year
        return year === String(currentYear) ? `${month}/${formattedDays}` : `${year}/${month}/${formattedDays}`;
      })
      .join(' ');
  }

  render() {
    const { orderStatus } = this.props;
    const OrderStatusLable = orderStatus['orderStatusMap'];
    const {
      addDocumentOpened,
      date_range: dateRange,
      fixedHeader,
      headerWidth,
      isChicagoForm,
      selectedOrders,
      sortTable,
      upload_doc_order: uploadDocOrder,
    } = this.state;
    const {
      current_order: currentOrder,
      current_orders: currentOrders,
      date_select: dateSelect,
      all_grinders: grinders,
      all_input_products: inputProducts,
      order_history: orderHistory,
      all_packer_plants: packerPlants,
      type,
      user,
    } = this.props;

    const dateRangeInputProps = {
      placeholder: 'Date range',
      className: 'form-control m-input',
    };

    const tableData = [...(isArray(currentOrders) ? currentOrders : currentOrders.items)];
    if (sortTable) {
      tableData.sort(this.sortTable);
    }

    const filteredTable = tableData.map(item => ({
      ...convertToCamelCase(item),
      packer_plant: findForeignItem(this.props.packer_plants, item.packer_plant_id),
      grinder: findForeignItem(this.props.grinders, item.grinder_uid),
    }));

    const internalPoLabel = getInternalPoLabel(this.props.configs);
    return (
      <div className="pending-order-page">
        <table
          ref={this.fixedHeaderTable}
          style={{ marginTop: '15px' }}
          className="table m-table table-hover m-table--head-separator-danger"
        >
          <thead>
            <tr>
              <th
                onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'delivery_date' ? 'delivery_date_reverse' : 'delivery_date',
                  })
                }
              >
                Delivery Date
                <i className="sort-icon fa fa-sort" />
              </th>
              <th
                onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'day' ? 'day_reverse' : 'day',
                  })
                }>
                Day <i className="sort-icon fa fa-sort" />
              </th>
              {orderHistory || type === 'history' ? (
                <th
                  onClick={e => {
                    if (e.target.type !== 'text') {
                      this.setState({
                        sortTable:
                          sortTable === 'grinder_po_number' ? 'grinder_po_number_reverse' : 'grinder_po_number',
                      });
                    }
                  }}
                >
                  Grinder PO#
                  <i className="sort-icon fa fa-sort" />
                </th>
              ) : (
                false
              )}
              <th
                onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'grinder' ? 'grinder_reverse' : 'grinder',
                  })
                }
              >
                Grinder
                <i className="sort-icon fa fa-sort" />
              </th>
              <th
                onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'packer' ? 'packer_reverse' : 'packer',
                  })
                }
              >
                Packer
                <i className="sort-icon fa fa-sort" />
              </th>
              <th onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'est' ? 'est_reverse' : 'est',
                  })
                } >Est # <i className="sort-icon fa fa-sort" /></th>
              <th
                onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'input_product' ? 'input_product_reverse' : 'input_product',
                  })
                }
              >
                Product
                <i className="sort-icon fa fa-sort" />
              </th>
              <th
                onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'quantity' ? 'quantity_reverse' : 'quantity',
                  })
                }
              >
                Quantity
                <i className="sort-icon fa fa-sort" />
              </th>
              {user.organisation.org_type.indexOf('grinder') === -1 ? (
                <th
                  onClick={() =>
                    this.setState({
                      sortTable:
                        sortTable === 'sell_price_per_unit' ? 'sell_price_per_unit_reverse' : 'sell_price_per_unit',
                    })
                  }
                >
                  Sell Unit Price
                  <i className="sort-icon fa fa-sort" />
                </th>
              ) : (
                false
              )}
              {!orderHistory ? (
                <th
                  onClick={() => {
                    if (selectedOrders.length === currentOrders.length) {
                      this.setState({ selectedOrders: [] });
                    } else {
                      this.setState({
                        selectedOrders: currentOrders,
                      });
                    }
                  }}
                >
                  Select Order
                </th>
              ) : (
                false
              )}

              <th>Estimated Pack Date</th>
              <th>Pack Date</th>
              <th onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'week_ending_date' ? 'week_ending_date_reverse' : 'week_ending_date',
                  })
                }>Week Ending  <i className="sort-icon fa fa-sort" /></th>
              <th
                onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'order_type' ? 'order_type_reverse' : 'order_type',
                  })
                }
              >
                Order Type <i className="sort-icon fa fa-sort" />
              </th>
              <th onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'price_per_unit' ? 'price_per_unit_reverse' : 'price_per_unit',
                  })
                }>Fob Price <i className="sort-icon fa fa-sort" /></th>
             
              <th>GHP <i className="sort-icon fa fa-sort" /></th>
              <th onClick={() =>
                  this.setState({
                    sortTable: sortTable === 'transport_cost_per_unit' ? 'transport_cost_per_unit_reverse' : 'transport_cost_per_unit',
                  })
                }>Freight Rate<i className="sort-icon fa fa-sort" /></th>
              <th>Packer Delivered</th>
              <th>Vendor So</th>
              <th>Order Type Notes</th>
              <th>Internal Comments</th>
            </tr>
          </thead>
          {filteredTable.map((order, i) => {
            return (
              <tbody key={`table-body-${i}`}>
                {order.lines.map((lineItem, index) => {
                  let thisPacker = false;
                  packerPlants.forEach(pp => {
                    if (pp.id === order.packerPlantId) {
                      thisPacker = pp;
                    }
                  });

                  let thisInputProduct = false;
                  inputProducts.forEach(ip => {
                    if (ip.uid === lineItem.inputProductUid) {
                      thisInputProduct = ip;
                    }
                  });

                  let thisGrinder = false;
                  grinders.forEach(g => {
                    if (g.uid === order.grinderUid) {
                      thisGrinder = g;
                    }
                  });
                  let rowBackgroundColor = false;
                  let cancelledOrder = false;

                  if (order.status === 'cancelled') {
                    cancelledOrder = true;
                  }

                  if (!lineItem.expectedProductionDate) {
                    rowBackgroundColor = {
                      background: 'rgba(255, 217, 102, 0.5)',
                      borderLeft: '2px solid rgb(255, 217, 102)',
                    };
                  }

                  if (!lineItem.active || order.status === 'cancelled') {
                    rowBackgroundColor = {
                      background: 'rgba(255,0,0,0.2)',
                      // background: '#fff4ee',
                      borderLeft: '2px solid rgba(255,0,0,0.6)',
                    };
                  }

                  if (order.status === 'received') {
                    rowBackgroundColor = {
                      background: '#c3e6cb',
                      borderLeft: '2px solid #28a745',
                    };
                   
                  }
                  let itemIsSelected = false;
                  selectedOrders.forEach(so => {
                    if (order.id === so.id) {
                      itemIsSelected = true;
                    }
                  });
                  // const thisEndUser = this.props.endUsers.find(obj => obj.id === order.endUserId);
                  return (
                    <tr
                      key={`table-${order.id}-${lineItem.id}`}
                      style={!rowBackgroundColor ? { borderLeft: '2px solid #dfdfdf' } : rowBackgroundColor}
                    >
                      {/*Delivery Date */}
                      <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                        {index > 0 ? null : (
                          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', position: 'relative' }}>
                            <Box>{order.deliveryDate}</Box>
                            {order.poLocked && (
                              <Tooltip
                                content="This purchase order is locked. Changes will not affect the grinder price."
                                placement="bottom"
                              >
                                <MdLockOutline size="18px" color="#1a202c" />
                              </Tooltip>
                            )}
                          </Box>
                        )}
                      </td>
                      <td> {new Date(order.deliveryDate).toLocaleDateString('en-US', { weekday: 'long' })}</td>

                      {/*PO Number  */}
                      {orderHistory || type === 'history' ? (
                        <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                          {index > 0 ? null : (
                            <a
                              href={`/order/search?grinder_po_number=${order.grinderPoNumber}&po_lookup_field=grinder_po_number&auto_open_po_card_purchase_order_id=${order.id}`}
                              target="_blank" // This ensures the link opens in a new tab
                              rel="noopener noreferrer" /* For security reasons:
                              - 'noopener' prevents the new page from having access to the original page's `window` object (mitigates potential malicious actions).
                              - 'noreferrer' ensures no referrer information is sent when navigating to the new page (protecting user privacy). */
                            >
                              {order.grinderPoNumber}
                            </a>)}
                        </td>
                      ) : (
                        false
                      )}
                      <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                        {index > 0 ? null : thisGrinder.name}
                      </td>
                      <td className={cancelledOrder ? 'cancelled-order' : 'req-field'}>
                        {index > 0 ? null : thisPacker.name}
                      </td>
                      <td>{thisPacker.est}</td>
                      <td>{thisInputProduct.name}</td>
                      <td>{commify(lineItem.buyQuantity)}</td>
                      <td>{(lineItem.sellPricePerUnit ? lineItem.sellPricePerUnit.toFixed(4) :'0.0000')}</td>
                      <td>{this.formatProductionDates(lineItem.estimatedProductionDetails)}</td>
                      <td>{this.formatProductionDates(lineItem.productionDetails)}</td>
                      <td>
                        {(() => {
                          const deliveryDate = new Date(order.deliveryDate); // Parse the delivery date
                          const dayOfWeek = deliveryDate.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)
                          const daysUntilSunday = 7 - dayOfWeek; // Calculate days until the next Sunday
                          const weekEndingDate = new Date(deliveryDate); // Create a copy of the date
                          weekEndingDate.setDate(deliveryDate.getDate() + daysUntilSunday); // Add the days to reach Sunday

                          // Format the date as YYYY-MM-DD
                          return weekEndingDate.toISOString().split('T')[0];
                        })()}
                      </td>
                      <td>{order.priceType ? order.priceType.toUpperCase(): ''}</td>
                      <td>{(lineItem.pricePerUnit ? lineItem.pricePerUnit.toFixed(4) : '0.0000')}</td>
                      <td>{(lineItem.groupHousedPremiumRate ? lineItem.groupHousedPremiumRate.toFixed(4) : '0.0000')}</td>
                      <td>{order?.transportCostPerUnit?.toFixed(4) || "N/A"}</td>                      
                      <td>{(lineItem.buyPricePerUnit ? lineItem.buyPricePerUnit.toFixed(4) : '0.0000')}</td>
                      <td>{order.packerSoNumber}</td>
                      <td>{lineItem.orderTypeNotes}</td>
                      <td>{order.internalComments}</td>
                      {index === 0 && !orderHistory ? (
                        <td rowSpan={order.lines.length}>
                          <Checkbox
                            mb={0}
                            marginX="auto"
                            onChange={this.selectOrder.bind(this, order)}
                            isChecked={itemIsSelected}
                          />
                          {order.status === 'pending' ? (
                            <Button
                              borderRadius={2}
                              bg="white"
                              width={6}
                              ml={3}
                              onClick={this.submitOrder.bind(this, order)}
                            >
                              <i className="fa fa-sign-in" />
                            </Button>
                          ) : (
                            false
                          )}
                        </td>
                      ) : (
                        false
                      )}
                    </tr>
                  );
                })}
              </tbody>
            );
          })}
        </table>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderStatus: state.orderStatusMap,
  };
};

export default connect(mapStateToProps)(POGridOrderTable);
