import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Center,
  Flex,
  IconButton,
  Tooltip,
  usePrevious,
} from '@chakra-ui/react';
import moment from 'moment';
import { capitalizeFirstLetter, formatMonetaryValue } from '../../../functions';
import { ReactComponent as ChatBubbleIconSVG } from '../../../assets/chat-bubble-outline.svg';
import { ReactComponent as EyeIconSVG } from '../../../assets/remove-red-eye.svg';
import { ReactComponent as CheckmarkIconSVG } from '../../../assets/checkmark.svg';
import { ReactComponent as ReleaseToPackerSVG } from '../../../assets/release-to-packer.svg';
import { ReactComponent as WarningIconSVG } from '../../../assets/warning-alert.svg';
import { ReactComponent as OverdueIconSVG } from '../../../assets/error-alert.svg';
import { ReactComponent as MonitoringIconSVG } from '../../../assets/search-b.svg';
import { ReactComponent as ArrowDownIconSVG } from '../../../assets/arrow-down.svg';
import { ReactComponent as ArrowUpIconSVG } from '../../../assets/arrow-up.svg';
import { ReactComponent as DeleteOutlineSVG } from '../../../assets/delete-outline.svg';
import { get, isEmpty, isEqual, isNull } from 'lodash';
import { Link } from 'react-router-dom';
import { convertToCamelCase, getOr, nestedGetOr } from '../../../helpers';
import { checkIfIsAdminUser, checkIfIsGrinderUser, checkIfIsPackerUser } from '../../../utils';
import { checkIfMonitoringRequired, checkIfOverdue, checkIfReleaseToPacker } from '../helpers';
import { connect } from 'react-redux';
import { findEntitiesInState } from '../../../slices/masterData/entityManagerSlice';
import styles from './claimListTable.module.css';

const renderNCRTypeTag = ncrType => {
  const value = ncrType || 'default';
  const colorConfig = {
    major: {
      color: '#FFFFFF',
      backgroundColor: '#FD5C38D1',
    },
    minor: {
      color: '#FFFFFF',
      backgroundColor: '#F2C94C',
    },
    serious: {
      color: '#FFFFFF',
      backgroundColor: '#DA1414',
    },
    default: {
      color: '#FFFFFF',
      backgroundColor: '#BFC5D2',
    },
  };

  return (
    <Center
      {...getOr(colorConfig, value, get(colorConfig, 'default'))}
      textTransform="capitalize"
      width="63px"
      height="26px"
      borderRadius="4px"
      fontWeight="500"
    >
      <Text>{ncrType.replace(/_/g, ' ') || 'N/A'}</Text>
    </Center>
  );
};

const renderStatus = status => {
  const displayValue = status.replace(/_/g, ' ');
  const colorConfig = {
    raised: {
      backgroundColor: '#53C0C3',
    },
    closed: {
      backgroundColor: '#D9D9D9',
    },
    rejected: {
      backgroundColor: '#FF4D4F',
    },
    in_progress: {
      backgroundColor: '#1890FF',
    },
    under_review: {
      backgroundColor: '#FAAD14',
    },
    accepted: {
      backgroundColor: '#52C41A',
    },
    default: {
      backgroundColor: '#53C0C3',
    },
  };

  return (
    <Flex alignItems="center">
      <Box
        backgroundColor={getOr(colorConfig, `${status}.backgroundColor`, get(colorConfig, 'default.backgroundColor'))}
        width="6px"
        height="6px"
        borderRadius="10px"
      />
      <Text textTransform="capitalize" fontWeight="400" marginLeft="8px">
        {displayValue}
      </Text>
    </Flex>
  );
};

const renderActions = ({
  data,
  onOpenCommunicationDrawer,
  caseId,
  userTypes: { isAdminUser, isPackerUser, isGrinderUser },
  onClickDelete,
  onReleaseToPacker,
  onOpenOverviewDrawer,
}) => {
  const userKey = isAdminUser ? 'seenByAdmin' : 'seenByOther';

  const unreadCount = getOr(data, 'ncrDetails.chatChannels', [])
    .filter(item => {
      if (isAdminUser) return true;
      return isPackerUser ? item.party === 'packer' : item.party === 'grinder';
    })
    .reduce((acc, curr) => {
      const messages = getOr(curr, 'messages', []);
      return acc + messages.reduce((accumulated, current) => (current[userKey] ? accumulated : accumulated + 1), 0);
    }, 0);

  const notification = getOr(data, 'notification', []).filter(item => {
    if (isAdminUser) return item.role === 'ncr_admin';
    return isPackerUser ? item.role === 'ncr_packer' : item.role === 'ncr_grinder';
  });

  const icons = notification.map(item => {
    if (item.approved)
      return (
        <Tooltip hasArrow label="Accepted" placement="top">
          <CheckmarkIconSVG />
        </Tooltip>
      );

    if (item.monitoringRequired)
      return (
        <Tooltip hasArrow label="Monitoring Required" placement="top">
          <MonitoringIconSVG />
        </Tooltip>
      );

    if (item.actionRequired)
      return (
        <Tooltip hasArrow label="Action Required" placement="top">
          <WarningIconSVG />
        </Tooltip>
      );

    return '';
  });

  return (
    <Flex alignItems="center">
      {icons.length > 0 ? (
        <HStack spacing="8px" marginRight="18px">
          {icons.map(icon => icon)}
        </HStack>
      ) : (
        ''
      )}
      {checkIfOverdue(data) ? (
        <Tooltip hasArrow label="Overdue" placement="top">
          <OverdueIconSVG />
        </Tooltip>
      ) : (
        ''
      )}
      {isAdminUser && checkIfMonitoringRequired(data) ? (
        <Tooltip hasArrow label="Monitoring Required" placement="top">
          <MonitoringIconSVG />
        </Tooltip>
      ) : (
        ''
      )}
      <HStack marginLeft="auto" spacing="12px">
        <Link to={`/ncr/${caseId}`}>
          <Button marginLeft="20px" colorScheme="actionSecondary" width="57px" height="28px" borderRadius="24px">
            Open
          </Button>
        </Link>
        <IconButton
          height="18px"
          minWidth="22px"
          backgroundColor="inherit"
          _focus={{ outline: 'none' }}
          icon={<EyeIconSVG />}
          onClick={() => onOpenOverviewDrawer(data)}
        />
        <Box position="relative">
          <IconButton
            height="18px"
            minWidth="22px"
            backgroundColor="inherit"
            _focus={{ outline: 'none' }}
            icon={<ChatBubbleIconSVG />}
            onClick={() => onOpenCommunicationDrawer(caseId)}
          />
          {unreadCount > 0 ? (
            <Center
              backgroundColor="accent.one.default"
              borderRadius="full"
              width="18px"
              height="18px"
              position="absolute"
              top="-10px"
              right="-10px"
            >
              <Text color="accent.one.800" fontSize="10px" fontWeight="700">
                {/* {unreadCount >= 9 ? '+9' : unreadCount} */}
                {unreadCount}
              </Text>
            </Center>
          ) : (
            ''
          )}
        </Box>
        {isAdminUser || (data.status === 'raised' && isGrinderUser) ? (
          <IconButton
            height="22px"
            minWidth="22px"
            backgroundColor="inherit"
            _focus={{ outline: 'none' }}
            icon={<DeleteOutlineSVG />}
            onClick={() => onClickDelete(caseId)}
          />
        ) : (
          ''
        )}
        {isAdminUser && checkIfReleaseToPacker(data) ? (
          <IconButton
            height="22px"
            minWidth="22px"
            backgroundColor="inherit"
            _focus={{ outline: 'none' }}
            icon={<ReleaseToPackerSVG />}
            onClick={() => onReleaseToPacker(caseId)}
          />
        ) : (
          ''
        )}
      </HStack>
    </Flex>
  );
};

const ClaimsListTable = ({
  onOpenCommunicationDrawer,
  ncrs,
  user,
  onClickDelete,
  onReleaseToPacker,
  onOpenOverviewDrawer,
  packerPlants,
  packers
}) => {
  const { searchResult } = ncrs;

  const [claimListData, setClaimListData] = useState([]);
  const searchResultProp = usePrevious(searchResult);
  useEffect(() => {
    if (!isEqual(searchResult, searchResultProp)) {
      setClaimListData(searchResult.map(data => ({ ...data, feKey: Math.ceil(Math.random() * 1000) })));
    }
  }, [searchResult, searchResultProp]);

  const [claimsListCols, setClaimsListCols] = useState([
    {
      key: 'case_id',
      paths: ['recordNo'],
      label: 'case id',
      sort: 'DESC',
      sortable: true,
    },
    {
      key: 'grinder_po',
      paths: ['ncrDetails.purchaseOrderLine.purchaseOrder.grinderPoNumber'],
      label: 'grinder po#',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'claim_type',
      paths: ['eventType'],
      label: 'claim type',
      sort: false,
      sortable: true,
    },
    {
      key: 'ncr_type',
      paths: ['claim.priority'],
      label: 'ncr type',
      sort: false,
      sortable: true,
      emptyValue: 'minor',
    },
    {
      key: 'claim_value',
      paths: ['claim.claimEntityFinalTotal', 'claim.claimEntityInitialTotal', 'ncrDetails.initialClaimValue'],
      label: 'claim value',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'packerPlant',
      paths: ['packerPlant.name'],
      label: 'Establishment',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'grinder',
      paths: ['grinder.name'],
      label: 'Grinder',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'packer',
      paths: ['packer'],
      label: 'Packer',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'category',
      paths: ['nonConformanceSubType.category'],
      label: 'category',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'sub category',
      paths: ['nonConformanceSubType.name'],
      label: 'sub category',
      sort: false,
      sortable: true,
      emptyValue: '-',
    },
    {
      key: 'status',
      paths: ['status'],
      label: 'status',
      sort: false,
      sortable: true,
    },
    {
      key: 'date_raised',
      paths: ['created'],
      label: 'date raised',
      sort: false,
      sortable: true,
      dataType: 'date',
      emptyValue: '-',
    },
    {
      key: 'last_update',
      paths: ['modified'],
      label: 'last update',
      sort: false,
      sortable: true,
      dataType: 'date',
      emptyValue: '-',
    },
    {
      key: 'actions',
      label: 'actions',
      sort: false,
      sortable: false,
    },
  ]);

  const renderPackerName = data => {
    const packerId = get(
      packerPlants.find(packerPlant => packerPlant?.id === get(data, 'packerPlantId')),
      'packerId',
      null
    );

    return get(
      packers.find(packer => packer?.id === packerId),
      'name',
      '-'
    );
  };

  const handleSort = colIndex => {
    let sortDir = null;
    const col = claimsListCols[colIndex];

    if (!col) return;
    if (!col.sort) sortDir = 'ASC';
    if (col.sort === 'ASC') sortDir = 'DESC';
    if (col.sort === 'DESC') sortDir = false;
    const sorted = (sortDir ? claimListData : searchResult).slice();
    sorted.sort((x, y) => {
      let sortVerdict;
      const a = nestedGetOr(0, col.paths, x, null);
      const b = nestedGetOr(0, col.paths, y, null);
      if (a && b && !isNaN(parseFloat(a)) && !isNaN(parseFloat(b))) {
        const parsedA = parseFloat(a);
        const parsedB = parseFloat(b);
        sortVerdict = sortDir === 'ASC' ? parsedA - parsedB : parsedB - parsedA;
      }
      if (a && b && moment(a).isValid() && moment(b).isValid()) {
        const parsedA = moment(a);
        const parsedB = moment(b);
        const parsedAComesFirst = parsedB.isAfter(parsedA) ? -1 : 1;
        const parsedBComesFirst = parsedA.isAfter(parsedB) ? -1 : 1;
        sortVerdict = sortDir === 'ASC' ? parsedAComesFirst : parsedBComesFirst;
      } else if (a && b && typeof a === 'string' && typeof b === 'string') {
        sortVerdict = sortDir === 'ASC' ? a.localeCompare(b) : b.localeCompare(a);
      } else if (isNull(a)) sortVerdict = sortDir === 'ASC' ? -1 : 1;
      else if (isNull(b)) sortVerdict = sortDir === 'ASC' ? 1 : -1;
      else if (isEmpty(a)) sortVerdict = sortDir === 'ASC' ? -1 : 1;
      else if (isEmpty(b)) sortVerdict = sortDir === 'ASC' ? 1 : -1;
      else sortVerdict = sortDir === 'ASC' ? a - b : b - a;
      return sortVerdict;
    });

    const changedColsState = claimsListCols.map(claimListCol => ({ ...claimListCol, sort: false }));
    changedColsState[colIndex].sort = sortDir;
    setClaimListData(sorted);
    setClaimsListCols(changedColsState);
  };

  const isAdminUser = checkIfIsAdminUser(user);
  const isPackerUser = checkIfIsPackerUser(user);
  const isGrinderUser = checkIfIsGrinderUser(user);

  return (
    <TableContainer>
      <Table variant="simple" className={styles.sticky_first_column}>
        <Thead>
          <Tr>
            {claimsListCols.map((col, index) => {
              let sortIndicator = (
                <VStack spacing="2px">
                  <ArrowUpIconSVG /> <ArrowDownIconSVG />
                </VStack>
              );

              if (col.sort === 'ASC') sortIndicator = <ArrowUpIconSVG />;
              if (col.sort === 'DESC') sortIndicator = <ArrowDownIconSVG />;
              return (
                <Th
                  key={col.key}
                  padding="12px 6px"
                  onClick={() => col.sortable && handleSort(index)}
                  cursor={col.sortable && 'pointer'}
                >
                  <HStack spacing="3px">
                    <Text fontSize="12px">{col.label}</Text> {col.sortable ? sortIndicator : ''}
                  </HStack>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody backgroundColor="#ffffff" fontSize="12px">
          {claimListData.map(data => {
            const notViewed = getOr(data, 'notification', [])
              .filter(item => {
                if (isAdminUser) return item.role === 'ncr_admin';
                return isPackerUser ? item.role === 'ncr_packer' : item.role === 'ncr_grinder';
              })
              .some(item => !item.viewed);
            return (
              <Tr
                key={getOr(data, claimsListCols[0].paths, getOr(data, 'feKey', 'na'))}
                fontWeight="400"
                backgroundColor={notViewed ? 'nav.horizontalBandBgColor' : 'inherit'}
              >
                {claimsListCols.map(col => {
                  const emptyValue = col.emptyValue || 'N/A';
                  const retrievedValue = nestedGetOr(0, col.paths || [], data, emptyValue);
                  let element = retrievedValue;
                  const ncrCurrencyCode = get(data, 'ncrDetails.purchaseOrderLine.purchaseOrder.sellCurrency');
                  if (col.key === 'claim_type')
                    element = retrievedValue === 'non_conformance' ? 'NCR' : capitalizeFirstLetter(retrievedValue);
                  if (col.key === 'ncr_type') element = renderNCRTypeTag(retrievedValue);
                  if (col.key === 'packer') element = renderPackerName(data);
                  if (col.key === 'claim_value' && retrievedValue !== emptyValue)
                    element = formatMonetaryValue(ncrCurrencyCode, retrievedValue, {
                      notation: 'standard',
                    });
                  if (col.key === 'status' && retrievedValue) element = renderStatus(retrievedValue);
                  if (col.dataType === 'date' && retrievedValue && moment(retrievedValue).isValid())
                    element = moment(retrievedValue).format('DD-MM-YYYY');
                  if (col.key === 'actions')
                    element = renderActions({
                      data,
                      onOpenCommunicationDrawer,
                      caseId: nestedGetOr(0, claimsListCols[0].paths || [], data, 'N/A'),
                      userTypes: { isAdminUser, isPackerUser, isGrinderUser },
                      onClickDelete,
                      onReleaseToPacker,
                      onOpenOverviewDrawer,
                    });
                  return (
                    <Td key={col.key} padding="9px 8px">
                      {element}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

ClaimsListTable.propTypes = {
  onOpenCommunicationDrawer: PropTypes.func,
  onOpenOverviewDrawer: PropTypes.func,
  onClickDelete: PropTypes.func,
  ncrs: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  packerPlants: PropTypes.arrayOf(PropTypes.any),
  packers: PropTypes.arrayOf(PropTypes.any),
};

// export default ClaimsListTable;

const mapStateToProps = state => {
  const result = {
    ...findEntitiesInState(state, {
      packer_plants: 'packer_plant',
      packers: 'packer',
    }),
  };
  const cameled = convertToCamelCase(result);
  return {
    ...cameled,
    packerOptions: cameled.packers.map(packer => ({
      ...packer,
      value: packer.id,
      label: packer.name,
    })),
  };
};

export default connect(mapStateToProps)(ClaimsListTable);
