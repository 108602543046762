import address from './address';
import address_port from './addressPort';
import notifying_action_groups from './notification_emails';
import country from './country';
import coldstore from './coldstore';
import cold_store_storage_fee from './coldStoreStorageFee';
import config_value from './config';
import contact from './contact';
import fdr_item from './FDRItem';
import fdr_rate from './FDRRate';
import fdr_freight_rate from './FDRFreightRate';
import fec from './fec';
import finance_factoring from './finance_factoring';
import form_fields from './formFields';
import freight_rate from './freightRate';
import grinder from './grinder';
import input_product from './inputProduct';
import interest from './interest';
import load_size from './loadSize';
import organisation from './organisation';
import output_product from './outputProduct';
import po_series from './poSeries';
import packer from './packer';
import packer_plant from './packerPlant';
import packer_plant_pork from './packerPlantPork';
import port from './port';
import shipping_line from './shippingLine';
import contract from './strategy';
import contract_price from './strategyPrice';
import strategy_type from './strategyType';
import transporter from './transporter';
import unit from './unit';
import voyage_leg from './voyageLeg';
import article_regions from './articleRegions';
import end_users from './endUsers';
import package_weight from './packageWeight';
import business_group from './businessGroup';
import ncrType from './ncrType';
import ncrSubType from './ncrSubType';
import specification from './specification';
import claimEntity from './claimEntity';
import nonConformanceType from './nonConformanceType';
import nonConformanceSubType from './nonConformanceSubType';
import celery_email_report_configs from './celeryemailreportconfigs';
import group_housed_premium_configs from './groupHousedPremiumConfigs';
import issuesXcodes from './issuesXcodes';
import price_agreements from './priceAgreements';

const schema = [
  ...input_product,
  ...output_product,
  ...packer_plant,
  ...packer_plant_pork,
  ...packer,
  ...end_users,
  ...grinder,
  ...po_series,
  ...transporter,
  ...coldstore,
  ...port,
  ...shipping_line,
  ...voyage_leg,
  ...fec,
  ...fdr_item,
  ...fdr_rate,
  ...fdr_freight_rate,
  ...interest,
  ...cold_store_storage_fee,
  ...address_port,
  ...finance_factoring,
  ...freight_rate,
  ...contract,
  ...contact,
  ...contract_price,
  ...strategy_type,
  ...address,
  ...config_value,
  ...form_fields,
  ...load_size,
  ...organisation,
  ...unit,
  ...article_regions,
  ...package_weight,
  ...business_group,
  ...specification,
  ...notifying_action_groups,
  ...country,
  ...claimEntity,
  ...nonConformanceType,
  ...nonConformanceSubType,
  ...ncrType,
  ...ncrSubType,
  ...celery_email_report_configs,
  ...group_housed_premium_configs,
  ...issuesXcodes,
  ...price_agreements
];

export default schema;
