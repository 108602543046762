import React, { useState, useEffect } from 'react';
import RightAngleIcon from '../core/RightAngleIcon';
import { fetchUsers, patchMyUser, updateUser } from '../../actions/actions_users';
import { forgotPassword } from '../../actions/actions_auth';
import Constants from '../../Constants';
import {
  HStack,
  VStack,
  Flex,
  IconButton,
  Switch,
  Heading,
  Text,
  Tooltip,
  Button,
  Box,
  Input,
  FormLabel,
  Collapse,
} from '@chakra-ui/react';
import TwoFactorMethodModal from '../basic/TwoFactorMethod';
import OptConfirmModal from '../basic/OptConfirm';
import { WarningIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { fetchTwoFactorAuthMethods, updateTwoFactorAuthMethod } from '../../services/two-factor-auth';
import DashboardSectionTile from '../core/DashboardSectionTile';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const inputContainerStyleProps = {
  marginLeft: 'auto !important',
  width: '264px',
};
const inputStyleProps = {
  height: '40px',
  backgroundColor: 'white',
  type: 'password',
};

const formFieldSection = {
  width: '400px',
};
const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('required'),
  newPassword: Yup.string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character'
    )
    .notOneOf([Yup.ref('currentPassword')], 'new password cannot be the same as old password')
    .required('required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'passwords must match')
    .required('required'),
});

const UserSettings = ({ user, token, password_reset, dispatch }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showConfirmOtpModal, setShowConfirmOtpModal] = useState(false);

  const [twoFactorAuthMethods, setTwoFactorAuthMethods] = useState([]);
  const [updatedMethod, setUpdatedMethod] = useState(null);

  useEffect(() => {
    const getMethods = async () => {
      const userMethods = await fetchTwoFactorAuthMethods(user.token);
      if (userMethods) {
        const methods = Constants.TWO_FACTOR_AUTH_METHOD.map(el => {
          const obj = userMethods.find(e => e.name === el.value);
          if (obj) {
            if (obj.name === user.default_two_factor_auth_method) {
              el.isDefault = true;
            } else {
              el.isDefault = false;
            }
            el.status = true;
            el.isConfirmed = obj.is_confirmed;
          } else {
            el.status = false;
          }
          return el;
        });
        setTwoFactorAuthMethods(methods);
      }
    };
    getMethods();
  }, []);

  const [showFormsState, setShowFormsState] = useState({
    changePasswordForm: true,
    twoFactorAuthForm: true,
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));

      const thisUser = { ...user };
      thisUser.current_password = values.currentPassword;
      thisUser.password = values.newPassword;
      const userObjKeys = Object.keys(thisUser);
      userObjKeys.map(objKey => {
        if (!['id', 'password', 'current_password'].includes(objKey)) delete thisUser[objKey];
        return null;
      });
      setSuccess(false);
      dispatch(patchMyUser(thisUser, token));
    },
    validateOnBlur: true,
  });

  useEffect(() => {
    setEmail(user.email);
    if (user.userUISwitched) {
      setSuccess(true);
      const msg = user.next_gen_ui
        ? Constants.USER_SETTINGS_UPDATED_MSG.USER_UI_SWITCH_TO_NEW
        : Constants.USER_SETTINGS_UPDATED_MSG.USER_UI_SWITCH_TO_OLD;
      setSuccessMsg(msg);
    } else if (user.userCredChanged || password_reset) {
      setSuccess(true);
      setSuccessMsg(Constants.USER_SETTINGS_UPDATED_MSG.USER_CRED_CHANGED);
    }
  }, [user, password_reset]);

  const userSetEmail = e => {
    const thisUser = { ...user };
    thisUser.email = email;
    setSuccess(false);
    dispatch(patchMyUser(thisUser, token));
  };

  const userSetPassword = e => {
    const thisUser = { ...user };
    thisUser.password = password;
    setSuccess(false);
    dispatch(patchMyUser(thisUser, token));
  };

  const userResetPassword = e => {
    dispatch(forgotPassword(user.email));
  };

  const switchToNextGenUi = e => {
    const thisUser = { ...user, next_gen_ui: !user.next_gen_ui, switchUI: true };
    dispatch(patchMyUser(thisUser, token));
  };

  const handleToggleChange = (value, status) => {
    setUpdatedMethod({ value, status });
    setShowPasswordModal(!showPasswordModal);
  };
  const handleTwoFactorAuthModal = (newStatus, success) => {
    const items = [...twoFactorAuthMethods];
    const index = twoFactorAuthMethods.findIndex(el => el.value === updatedMethod.value);
    items[index].status = newStatus;
    items[index].isConfirmed = false;
    setTwoFactorAuthMethods(items);
    setShowPasswordModal(false);
    if (success && newStatus) {
      setShowConfirmOtpModal(true);
    }
  };
  const handleConfirmOtp = success => {
    if (success) {
      const items = [...twoFactorAuthMethods];
      const index = twoFactorAuthMethods.findIndex(el => el.value === updatedMethod.value);
      items[index].isConfirmed = true;
      setTwoFactorAuthMethods(items);
    }
    setShowConfirmOtpModal(false);
  };

  const showOtpModal = (method, status) => {
    setUpdatedMethod({ value: method, status });
    setShowConfirmOtpModal(true);
  };

  const handleSetDefaultMethod = async method => {
    await updateTwoFactorAuthMethod(user.token, method, true);
    const thisUser = { ...user };
    thisUser.default_two_factor_auth_method = method;
    dispatch(updateUser(thisUser));
  };

  return (
    <>
      {showPasswordModal && (
        <TwoFactorMethodModal
          user={user}
          method={updatedMethod.value}
          handleChangeActiveStatus={handleTwoFactorAuthModal}
          isActive={updatedMethod.status}
        />
      )}
      {showConfirmOtpModal && (
        <OptConfirmModal user={user} method={updatedMethod.value} handleCloseModal={handleConfirmOtp} />
      )}
      <DashboardSectionTile
        title="Change your Password"
        tileStatusComponent={() => (
          <IconButton
            variant="unstyled"
            marginLeft="auto !important"
            fontSize="20px"
            icon={showFormsState.changePasswordForm ? <ChevronUpIcon /> : <ChevronDownIcon />}
            onClick={() =>
              setShowFormsState({ ...showFormsState, changePasswordForm: !showFormsState.changePasswordForm })
            }
          />
        )}
        paddingRight="18px"
      >
        <Collapse in={showFormsState.changePasswordForm}>
          <form onSubmit={formik.handleSubmit}>
            <VStack align="stretch" spacing="21px" margin="27px 40px 2px 34px">
              <HStack {...formFieldSection}>
                <FormLabel>Old Password:</FormLabel>
                <Box {...inputContainerStyleProps}>
                  <Input
                    name="currentPassword"
                    {...inputStyleProps}
                    value={formik.values.currentPassword}
                    onChange={formik.handleChange}
                    borderColor={formik.errors.currentPassword && formik.touched.currentPassword && 'red'}
                  />
                  {formik.errors.currentPassword && formik.touched.currentPassword ? (
                    <Text as="p" color="red" fontSize="12px">
                      {formik.errors.currentPassword}
                    </Text>
                  ) : null}
                </Box>
              </HStack>
              <HStack {...formFieldSection}>
                <FormLabel>New Password:</FormLabel>
                <Box {...inputContainerStyleProps}>
                  <Input
                    name="newPassword"
                    {...inputStyleProps}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    borderColor={formik.errors.newPassword && formik.touched.newPassword && 'red'}
                  />
                  {formik.errors.newPassword && formik.touched.newPassword ? (
                    <Text as="p" color="red" fontSize="12px">
                      {formik.errors.newPassword}
                    </Text>
                  ) : null}
                </Box>
              </HStack>
              <HStack {...formFieldSection}>
                <FormLabel>Confirm Password:</FormLabel>
                <Box {...inputContainerStyleProps}>
                  <Input
                    name="passwordConfirmation"
                    {...inputStyleProps}
                    value={formik.values.passwordConfirmation}
                    onChange={formik.handleChange}
                    borderColor={formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && 'red'}
                  />
                  {formik.errors.passwordConfirmation && formik.touched.passwordConfirmation ? (
                    <Text as="p" color="red" fontSize="12px">
                      {formik.errors.passwordConfirmation}
                    </Text>
                  ) : null}
                </Box>
              </HStack>
              <Button
                colorScheme="actionSecondary"
                height="43px"
                width="179px"
                fontWeight="medium"
                fontSize="16px"
                marginLeft="auto !important"
                marginTop="30px !important"
                type="submit"
              >
                Update Password
              </Button>
            </VStack>
          </form>
        </Collapse>
      </DashboardSectionTile>
      <div className="m-grid__item m-grid__item--fluid m-wrapper">
        <div className="m-content">
          <div className="row">
            <div className="col-xl-12">
              <div className="m-portlet m-portlet--full-height ">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-tools">
                    <ul className="nav nav-tabs m-tabs m-tabs-line admin-tabs" role="tablist">
                      <li key="list-menu-user-settings" className="nav-item m-tabs__item active">
                        <a className="nav-link m-tabs__link">User Settings</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="m-portlet__body" style={{ minHeight: '320px' }}>
                  <div className="tab-content">
                    <div className="tab-pane active" id="m_widget2_tab4_content">
                      <div style={{ padding: '20px' }}>
                        <div className="row">
                          <div className="col-sm-7">
                            <p>On this screen, you can manage some of your user settings.</p>
                            <div className="form-group">
                              <label
                                htmlFor="username-form"
                                className="user-settings control-label visible-ie8 visible-ie9"
                              >
                                Email Address
                              </label>
                              <input
                                id="username-form"
                                className="user-settings form-control form-control-solid placeholder-no-fix"
                                type="text"
                                autoComplete="off"
                                placeholder="Username"
                                name="username"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                              />
                              <button
                                style={{ marginTop: '10px' }}
                                onClick={userSetEmail}
                                className="user-settings btn btn-success email"
                                type="submit"
                              >
                                Update Email
                              </button>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="password-reset-form"
                                className="user-settings control-label visible-ie8 visible-ie9"
                              >
                                Reset Password
                              </label>
                              <br />
                              <button
                                style={{ marginTop: '10px' }}
                                onClick={userResetPassword}
                                className="user-settings btn btn-success password-reset"
                                type="submit"
                              >
                                Reset Password
                              </button>
                              <br />
                              <button
                                style={{ marginTop: '10px' }}
                                onClick={switchToNextGenUi}
                                className="user-settings btn btn-success password-reset"
                                type="submit"
                              >
                                {user.next_gen_ui ? 'Switch back to old design' : 'Switch to new design'}
                              </button>
                            </div>
                            {success ? (
                              <div className="form-group">
                                <div className="alert alert-info">{successMsg}</div>
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserSettings;
