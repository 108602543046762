import React, { useCallback, useEffect, useState } from 'react';
import { Box, HStack, Select, IconButton, Flex, VStack, Heading, Stack, Skeleton, Text, useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { RiPencilFill, RiDeleteBin7Fill  } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';
import { PaginationBar } from '../../core/PaginationComponents';
import NoData from '../_components/NoData';
import RightAngleIcon from '../../core/RightAngleIcon';
import Tooltip from '../_components/Tooltip';
import { fetchResources, actions } from '../../../reducers/reducer_food_safety_quality';
import AddDocument from '../_components/AddDocument';
import ViewDocument from '../_components/ViewDocument';
import { getStatusColor } from '../../../helpers';
import PackerSelectBox from '../_components/PackerSelectBox';
import Filters from '../_components/Filters';
import DownloadResource from '../_components/DownloadResource';
import Constants from '../../../Constants';
import api from '../../../api';
import DeleteDocumentModal from '../_components/DeleteDocument';

const fmgColumns = [
  {
    title: 'TITLE',
    dataIndex: 'rs_title',
    key: 'title',
  },
  {
    title: 'PACKER',
    dataIndex: 'packer',
    key: 'packer',
    render: packer => packer.name,
  },
  {
    title: 'Establishment Name',
    dataIndex: 'packer_plant',
    key: 'packer_plant',
    render: packer_plant => (packer_plant ? packer_plant.name : '-'),
  },
  {
    title: 'Est No.',
    dataIndex: 'packer_plant',
    key: 'packer_plant',
    render: packer_plant => (packer_plant ? packer_plant?.est : '-'),
  },      
  {
    title: 'DOCUMENT NO.',
    dataIndex: 'rs_doc_number',
    key: 'rs_doc_number',
    sorter: (a, b) => {
      const resourceA = a.rs_doc_number.toUpperCase();
      const resourceB = b.rs_doc_number.toUpperCase();
      if (resourceA < resourceB) {
        return -1;
      }
      if (resourceA > resourceB) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: 'ISSUED DATE',
    dataIndex: 'rs_issued_date',
    key: 'issued_date',
    sorter: (a, b) => {
      return moment(a.rs_issued_date).unix() - moment(b.rs_issued_date).unix();
    },
    render: date => (date ? moment(date).format('DD MMM YYYY') : '-'),
  },
  {
    title: 'LAST UPDATED',
    dataIndex: 'rs_last_updated',
    key: 'last_updated',
    sorter: (a, b) => {
      return moment(a.rs_last_updated).unix() - moment(b.rs_last_updated).unix();
    },
    render: date => (date ? moment(date).format('DD MMM YYYY') : '-'),
  },
  {
    title: 'EXPIRED ON',
    dataIndex: 'rs_expired_on',
    key: 'rs_expired_on',
    sorter: (a, b) => {
      return moment(a.rs_expired_on).unix() - moment(b.rs_expired_on).unix();
    },
    render: date => (date ? moment(date).format('DD MMM YYYY') : '-'),
  },
  {
    title: 'UPLOADED BY',
    dataIndex: 'uploaded_by',
    key: 'uploaded_by',
    sorter: (a, b) => {
      const resourceA = a.uploaded_by.toUpperCase();
      const resourceB = b.uploaded_by.toUpperCase();
      if (resourceA < resourceB) {
        return -1;
      }
      if (resourceA > resourceB) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    render: status => (
      <Text
        as="span"
        fontSize="11px"
        fontWeight="500"
        letterSpacing="0.4px"
        padding="4px 12px"
        borderRadius="6px"
        background={getStatusColor(status).bgColor}
        color={getStatusColor(status).color}
        textTransform="capitalize"
        whiteSpace="nowrap"
      >
        {status}
      </Text>
    ),
  },
  {
    title: 'ACTION',
    dataIndex: 'actions',
    key: 'actions',
  },
];

const filtersList = [
  { label: 'All', value: {} },
  { label: 'Alphabetically (A-Z)', value: { sortby: 'alphabetic' } },
  { label: 'Active', value: { status: 'Active' } },
  { label: 'Expired', value: { status: 'Expired' } },
  { label: 'Expiring Soon', value: { status: 'Expiring Soon' } },
];

/**
 * Renders the HACCPLetters component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpenAddPopup - Flag indicating whether the edit popup is open.
 * @param {function} props.setIsOpenAddPopup - Function to set the state of isOpenAddPopup.
 * @returns {JSX.Element} The HACCPLetters component.
 */
const HACCPLetters = ({ isOpenAddPopup, setIsOpenAddPopup }) => {
  const dispatch = useDispatch();
  const fmgDocsState = useSelector(state => state.food_safety_quality);
  const [editItem, setEditItem] = useState(null);
  const [filters, setFilters] = useState({});
  const [packerId, setPackerId] = useState('');
  const [deleteDocument, setDeleteDocument] = useState(false);
  const toast = useToast();  

  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    currentPage: 1,
    query: '',
  });

  const onEditHandler = _item => {
    setEditItem(_item);
    setIsOpenAddPopup(true);
  };

  const handleItemsPerPageSelect = e => {
    const currentPageValue = 1;
    const itemsPerPageValue = parseInt(e.target.value, 10);

    setPaginationState({
      ...paginationState,
      itemsPerPage: itemsPerPageValue,
      currentPage: currentPageValue,
    });
  };

  const handlePageClick = selected => {
    setPaginationState({ ...paginationState, currentPage: selected });
  };

  const fetchResourcesHandler = useCallback(
    debounce(pState => dispatch(fetchResources(Constants.RESOURCE_DOCUMENT_TYPES.haccp, pState)), 300),
    []
  );

  const onDeleteHandler = (item) => {
    setDeleteDocument(true);
    setEditItem(item);
  }

  const handleDelete = async (id) => {
    try {
      const response = await api.delete(`/op-doc-resources/${id}`);
      await Constants.handleErrors(response, dispatch);
      dispatch(actions.deleteResourceSuccess(id,Constants.RESOURCE_DOCUMENT_TYPES.haccp));
      toast({
        title: 'Document deleted Successfully',
        position: 'top-right',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });      
    } catch (error) {
      toast({
        title: error.message,
        position: 'top-right',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });      
    }
    setDeleteDocument(false);
  };  

  useEffect(() => {
    fetchResourcesHandler({ ...paginationState, ...filters, packerId });
  }, [paginationState.currentPage, paginationState.itemsPerPage, paginationState.query, filters, packerId]);

  useEffect(() => {
    if (!isOpenAddPopup) setEditItem(null);
  }, [isOpenAddPopup]);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" width="100%" marginBlock="20px">
        <HStack spacing="10px">
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            HACCP Letters
          </Heading>
        </HStack>

        <Flex alignItems="center">
          <Filters
            value={filters}
            onChange={value => {
              setFilters(value);
              setPaginationState(prevState => ({
                ...prevState,
                currentPage: 1,
              }));
            }}
            options={filtersList}
          />
          <Box width="264px">
            <PackerSelectBox
              value={packerId}
              onChange={e => {
                setPackerId(e.target.value);
                setPaginationState(prevState => ({
                  ...prevState,
                  currentPage: 1,
                }));
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <VStack
        align="stretch"
        spacing="20px"
        padding="30px"
        boxShadow="md"
        borderRadius="6px"
        backgroundColor="card.default"
        width="100%"
        minHeight="calc(100vh - 320px)"
      >
        {fmgDocsState.loading && (
          <Stack>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
              <Skeleton key={i} height="25px" />
            ))}
          </Stack>
        )}

        {!fmgDocsState.loading && fmgDocsState.haccp && fmgDocsState?.haccp?.items?.length ? (
          <>
            <Table
              pagination={{
                pageSize: paginationState.itemsPerPage,
                current: paginationState.currentPage,
                style: { display: 'none' },
              }}
              rowClassName="food-safety-table-row"
              className="food-safety-table"
              dataSource={fmgDocsState?.haccp?.items?.map(itm => ({
                ...itm,
                actions: (
                  <Flex gap={3} css={{ gap: '10px' }}>
                    <Tooltip content="Edit">
                      <IconButton
                        width="24px"
                        height="24px"
                        padding="0"
                        minW="auto"
                        border="1px solid #D9DADF"
                        borderRadius="2px"
                        color="#00000099"
                        backgroundColor="#fff"
                        icon={<RiPencilFill size={12} />}
                        onClick={() => onEditHandler(itm)}
                      />
                    </Tooltip>
                    { itm?.rs_path.endsWith('doc') || itm?.rs_path.endsWith('docx') ? () => ('') : <ViewDocument url={itm.rs_path} /> }
                    <DownloadResource url={itm.rs_path} />
                    <Tooltip content="Delete">
                      <IconButton
                        width="24px"
                        height="24px"
                        padding="0"
                        minW="auto"
                        border="1px solid #D9DADF"
                        borderRadius="2px"
                        color="#00000099"
                        backgroundColor="#fff"
                        icon={<RiDeleteBin7Fill size={12} />}
                        onClick={() => onDeleteHandler(itm)}
                      />
                    </Tooltip>                      
                  </Flex>
                ),
              }))}
              columns={fmgColumns}
            />
            <Flex marginTop="auto !important">
              <HStack marginLeft="auto" minW="100px">
                <Select
                  width="fit-content"
                  marginLeft="auto"
                  onChange={e => handleItemsPerPageSelect(e)}
                  value={paginationState.itemsPerPage}
                >
                  {[10, 20, 30, 40, 50].map(item => (
                    <option key={item} value={item}>
                      Show {item}
                    </option>
                  ))}
                </Select>
              </HStack>
              <PaginationBar
                currentPage={paginationState.currentPage}
                pages={fmgDocsState?.haccp?.pages}
                onPageChange={page => handlePageClick(page)}
                justifyContent="flex-end"
              />
            </Flex>
          </>
        ) : (
          !fmgDocsState.loading && <NoData />
        )}
      </VStack>
      {isOpenAddPopup && (
        <AddDocument
          editItem={editItem}
          resourceType={Constants.RESOURCE_DOCUMENT_TYPES.haccp}
          isOpenAddPopup={isOpenAddPopup}
          setIsOpenAddPopup={setIsOpenAddPopup}
        />
      )}
      {deleteDocument && (
        <DeleteDocumentModal
          show={deleteDocument}
          editItem={editItem}
          handleClose={() => setDeleteDocument(false)}
          handleDelete={handleDelete}/>
      )}      
    </>
  );
};

HACCPLetters.propTypes = {
  isOpenAddPopup: PropTypes.bool,
  setIsOpenAddPopup: PropTypes.func,
};

export default HACCPLetters;
