const schema = [
    {
      name: 'price_agreements',
      displayName: 'Price Agreement',
      nested: false,
      systemIdField: 'id',
      displayIdField: 'packer_plant_id',
      deletable: false,
      tabGroup: { key: 'pricing', label: 'Pricing' },
      createMultiple: false,
      path: '/price_agreements',
      fields: [
        {
          name: 'id',
          displayName: 'ID',
          dataType: 'integer',
          required: true,
          systemIdentifier: true,
          hidden: true,
        },
        {
            name: 'product_type',
            displayName: 'Product Type',
            tooltip: 'Select the Product type, either Beef or Pork.',
            dataType: 'reference',
            referenceType: 'select',
            referenceEntityType: 'form_fields',
            referenceEntitySubType: 'product_types',
            required: true,
        },       
        {
          name: 'price_agreement',
          displayName: 'Price Agreement',
          tooltip: 'Price Agreement',
          dataType: 'text',
          required: true,
        },        
        {
          name: 'delisted',
          displayName: 'Is Delisted',
          dataType: 'boolean',
        },
      ],
    },
  ];
  export default schema;