import { connect } from 'react-redux';

import POGridOrderTableComponent from '../../components/orders/OrderTable/POGridOrderTable';
import { findAllEntitiesWithoutFilterInState, findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state),
    selectedOrders: state.selectedOrders,
    sortTable: state.sortTable,
    fixedHeader: state.fixedHeader,
    headerWidth: state.headerWidth,
    poFilter: state.poFilter,
    date_range: state.date_range,
    current_order: state.orders.netsuite_purchase_order,
    token: state.user.token,
    configs: state.configs,
    endUsers: state.end_users,
    error: state.error,
    ...findAllEntitiesWithoutFilterInState(state, {
      all_grinders: 'grinder',
      all_packer_plants: 'packer_plant',
      all_input_products: 'input_product',
    }),
  };
};

const GridOrderTable = connect(mapStateToProps)(POGridOrderTableComponent);

export default GridOrderTable;
